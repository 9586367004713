@import 'styles/index.scss';

.container {
    padding: $spacing-md $spacing-xl;
    width: 100%;
    flex-direction: column;
    overflow: auto;

    .section {
        max-width: 500px;
        position: relative;
        border: 1px solid $border-color;
        border-radius: $border-radius-rounded;
        padding: $spacing-md;
        margin-top: $spacing-lg;
        margin-bottom: $spacing-lg;

        .label {
            position: absolute;
            background-color: #fff;
            top: -13px;
            padding: 0 12px;
            left: $spacing-md;
            font-size: $font-size-lg;
            font-weight: 500;
        }

        .refresh {
            flex: 1;
        }

        .row {
            display: flex;
            margin: $spacing-lg auto;
        }

        .accountStateLabel {
            flex: 1;
        }
    }

    .actions {
        @include user-select-none;
    }

    .advancePaymentLabel {
        flex: 1;
    }
}

@include is-screen-smaller-than(md) {
    .container {
        padding-left: $spacing-md;
        padding-right: $spacing-md;
        align-content: center;
        min-width: fit-content;

        > label {
            display: block;
            margin-top: $spacing-md;
            margin-bottom: $spacing-xs;
        }
    }
}
