@import 'styles/index.scss';

.container {
    height: 100%;
    overflow: hidden;

    .header {
        @include basic-header;
        align-items: center;
        justify-content: flex-start;
        padding-right: $spacing-md;
        padding-left: 100px;

        .userName {
            margin-left: $spacing-md;
            overflow: hidden;
            white-space: nowrap;

            p {
                margin: 0;
                font-weight: 700;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            span {
                font-size: $font-size-sm;
            }
        }
    }

    .menuContainer {
        height: calc(100% - #{$header-height-with-padding});
        background-color: #fff;
        overflow-y: auto;
        padding-top: 0;
        padding-bottom: 0;
        border-right: 1px solid $border-color;
        display: flex;
        flex-direction: column;

        .menu {
            padding-top: 0;
            padding-bottom: 0;
            border-right: none;
        }
    }

    .logout {
        background-color: #fff;
        padding-right: $spacing-md;
        padding-left: $menu-width;
    }
}
