@import 'styles/index.scss';

.container {
    display: flex;
    flex-direction: column;
    min-width: 324px;

    & > div {
        border-bottom: 1px solid $box-shadow-color-light;
    }

    .addEmployee {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: $spacing-sm;
        @include user-select-none;

        &:hover {
            cursor: pointer;
            background-color: $background-light;
        }

        span {
            color: $primary-color;
            margin-right: $spacing-sm;
        }

        &.active {
            background-color: #eceff2;
            @include is-screen-smaller-than(xxs) {
                background-color: transparent;
            }
        }
    }

}
