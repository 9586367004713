@import 'styles/shared.scss';

$img-margin-top: 3rem;

.container {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    padding: 20px;
    padding-bottom: 140px;
    @include user-select-none;

    .vetImg {
        max-height: 400px;
        margin-top: $img-margin-top;
        margin-right: $spacing-lg;
    }
    .petImg {
        max-height: 300px;
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);

        // correct margin inside the svg to align bottom with the vet image
        margin-top: calc(#{$img-margin-top} + 7.1rem);
        margin-left: 1rem;
    }

    @include is-screen-smaller-than('md') {
        .vetImg,
        .petImg {
            display: none;
        }
    }
}
