@import 'styles/shared.scss';

.container {
    position: relative;
    display: flex;
    height: 100%;

    .main {
        flex: 1;
        display: flex;
        flex-direction: column;
    }
}
