@import 'styles/index.scss';

$profile-in-profile-size: calc(0.4 * #{$profile-image-size});

.container {
    width: $profile-image-size;
    height: $profile-image-size;
    min-width: $profile-image-size;
    min-height: $profile-image-size;
    border-radius: 50%;

    position: relative;

    &.clickable:hover {
        cursor: pointer;
    }

    .profile {
        height: 100%;
        width: 100%;
        min-width: $profile-image-size;
        object-fit: cover;
        border-radius: 50%;
    }

    .profilePlaceholderBackground  {
        height: $profile-image-size;
        width: $profile-image-size;
        border-radius: 50%;
        background-color: $primary-color;
    }

    .profileInProfile {
        position: absolute;
        bottom: 0;
        right: 0;
        height: $profile-in-profile-size;
        width: $profile-in-profile-size;
        object-fit: cover;
        border-radius: 50%;
        border: 2px solid #ffffff;

        &.left {
            left: 0;
            right: unset;
        }
    }
}
