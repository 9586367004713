@import 'styles/index.scss';

.container {
    list-style-type: none;
    color: $font-color;
    display: flex;
    align-items: center;

    .icon {
        margin-right: 10px;
    }

    span {
        flex: 1;
        width: 100%;
        // white-space: nowrap;
        // overflow-x: hidden;
        // text-overflow: ellipsis;
    }

    .tooltipIcon {
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
