@import 'styles/index.scss';

.container {
    @include box-shadow-strong;
    border-radius: $border-radius-rounded;
    background-color: $primary-color;
    color: #ffffff;
    padding: $spacing-md;
    display: flex;
    font-size: $font-size-sm;
    justify-content: space-between;
    width: 300px;
    box-sizing: border-box;
    z-index: $z-index-alert;

    &.error {
        background-color: $animal-red;
    }

    &.success {
        background-color: $animal-green;
    }

    .text {
        margin: auto $spacing-sm;
        flex: 2;
    }

    .icon {
        fill: #ffffff;
        color: #ffffff;
    }

    .close:hover {
        cursor: pointer;
    }
}
