@import 'styles/index.scss';

.container {
    padding: $spacing-sm;
    display: flex;
    flex-direction: column;
    padding-top: $spacing-lg;
    padding-bottom: $spacing-lg;
    min-width: 300px;

    .logo {
        width: 96px;
        height: 96px;
    }

    .row {
        display: flex;
        align-items: center;
        padding-top: $spacing-md;
        padding-bottom: $spacing-md;

        .field {
            flex: 1;
            display: flex;
            flex-direction: column;
            font-size: $font-size-sm;

            &.companyName {
                font-size: $font-size-md;
                font-weight: 700;
            }

            p {
                margin: 0;
            }

            .name {
                font-weight: 700;
                color: $primary-color;
                font-size: $font-size-sm;
            }
        }

        .editIcon {
            &:hover {
                cursor: pointer;
            }
        }
    }
}
