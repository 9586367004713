@import 'styles/index.scss';

.container {
    padding: $spacing-sm $spacing-xl;
    max-width: 500px;
    flex: 1;

    .title {
        color: #000000;
        font-weight: 700;
    }

    .input {
        box-shadow: inset 0 2px 4px $box-shadow-color;
        border: 1px solid $border-color;
        border-radius: $border-radius-rounded;
        width: 100%;
        resize: none;
        flex: 1;
    }

    .actions {
        display: flex;
        gap: $spacing-sm;
        align-items: center;
        justify-content: flex-end;
        @include user-select-none;
    }
}
