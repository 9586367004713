@import 'styles/shared.scss';

.row {
    margin-top: $spacing-md;
    margin-bottom: $spacing-md;
    input {
        border-radius: $border-radius-rounded !important;
    }

    .prefixText {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: $spacing-md;
    }

    .phoneNumberInput {
        border-radius: $border-radius-rounded !important;
        padding-left: calc(#{$spacing-lg} + 40px) !important;
        text-align: left !important;
    }

    .switchLabel {
        font-weight: 500;
        color: $primary-color;
        margin-top: 0;
        margin-bottom: $spacing-sm;
    }

    .changeDetailsLater {
        font-size: $font-size-sm;
    }

    .phoneNumberError {
        margin-bottom: 0;
    }

    > p {
        margin: 0;
    }
}

.nextButton {
    margin: $spacing-md 0;
}

.legend {
    margin: $spacing-md 0;
}
