@import 'styles/shared.scss';

.container {
    position: relative;
    display: flex;
    height: 100%;
    min-height: 100%; // important!

    .main {
        flex: 1;
        display: flex;
        flex-direction: column;

        .messageReferenceContainer {
            display: flex;
            flex-direction: row;
            background-color: $background-light;

            .messageReference {
                padding: 10px;
                align-self: flex-start;
                max-width: 80%;
            }

            .closeButton {
                padding: 10px;
                justify-self: center;
                align-self: center;
                flex: 1;
                min-width: 40px;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}
