@import 'styles/index.scss';

$field-size: 0.4;

.container {
    flex: 1;
    padding: $spacing-md $spacing-xl;
    display: grid;
    gap: $spacing-md;
    grid-template-columns: 1fr 3fr;
    align-content: start;
    align-items: center;
    max-width: 500px;

    .actions {
        grid-column-start: span 2;
        gap: $spacing-sm;
        display: flex;
        justify-content: flex-end;
        @include user-select-none;
    }
}

@include is-screen-smaller-than(md) {
    .container {
        display: block;
        padding-left: $spacing-md;
        padding-right: $spacing-md;
        align-content: center;
        min-width: fit-content;
        overflow: auto;

        > label {
            display: block;
            margin-top: $spacing-md;
            margin-bottom: $spacing-xs;
        }
    }
}
