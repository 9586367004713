@import 'styles/index.scss';

.container {
    margin-top: $spacing-md;
    margin-bottom: $spacing-md;
    border-radius: $border-radius;
    font-size: $font-size-md;
    padding-left: 12px;
    padding-right: 12px;
    transition: background-color 0.2s ease-in-out;

    &:hover {
        cursor: pointer;
        background-color: $background-light;
    }

    &.disabled {
        color: $button-disabled;
        &:hover {
            cursor: not-allowed;
            background-color: $background-light;
        }
    }
}

.primary {
    background-color: $background-extra-light;
    border: 0px;
    color: $primary-color;
    padding-top: 10px;
    padding-bottom: 10px;
}

.secondary {
    background-color: transparent;
    border: 0px;
    color: #000000;
    padding-top: 9px;
    padding-bottom: 9px;
}
