@import 'styles/shared.scss';

$navigation-width: 400px;
$animation-time: 0.2s;
$main-width: calc(100% - #{$navigation-width});
@include fade-from-left(#{$navigation-width});
@include fade-from-right(#{$main-width});

.container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @include is-screen-larger-than(xxs) {
        &.show {
            display: unset;
            z-index: $z-index-user-profile;

            .navigation {
                animation: fade-from-left $animation-time;
            }

            .main {
                animation: fade-from-right $animation-time;
            }
        }
    }

    &.hide {
        display: unset;
        z-index: $z-index-user-profile;

        .navigation {
            animation: fade-from-left $animation-time reverse;
        }
        .main {
            animation: fade-from-right $animation-time reverse;
        }
    }
    @include is-screen-larger-than(xxs) {
        &.shown {
            display: unset;
            z-index: $z-index-user-profile;

            .navigation {
                left: 0;
            }
            .main {
                right: 0;
            }
        }
    }

    &.hidden {
        display: none;
        .navigation {
            left: -$navigation-width;
        }
        .main {
            right: calc(-1 * #{$main-width});
        }
    }

    .navigation {
        position: absolute;
        top: 0;
        left: -$navigation-width;
        bottom: 0;
        width: $navigation-width;
        display: flex;
        flex-direction: column;
    }

    .main {
        position: absolute;
        top: 0;
        bottom: 0;
        right: calc(-1 * #{$main-width});
        width: $main-width;
        display: flex;
        flex-direction: column;
    }

    @include is-screen-smaller-than(xxs) {
        .navigation {
            display: flex;
            flex-direction: column;
            width: 100vw;
            left: 0px;
            right: 0px;
        }
        .main {
            position: absolute;
            height: 100vh;
            width: 100vw;
            top: 0px;
            right: 0px;
            left: calc(100vw);
            bottom: 0px;
            transition: 0.2s;
            z-index: $z-index-mobile-main;
            overflow-y: hidden;
        }
    }
}
