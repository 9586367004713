@import 'styles/shared.scss';

$img-size: 85px;

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 120px;
    @include user-select-none;

    > * {
        margin-top: $spacing-xs;
        margin-bottom: $spacing-xs;
    }

    .logo {
        height: $img-size;
        width: $img-size;
    }

    h4 {
        font-size: 1.1rem;
        font-weight: 900;
    }

    .text {
        text-align: center;
        font-size: $font-size-xs;
        font-weight: 500;
        @include user-select-none;
    }

    .textButton {
        &:hover {
            background-color: transparent;
        }
    }

    .checkboxes {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .checkbox {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: $spacing-xs;
            margin-bottom: $spacing-xs;

            span {
                padding-left: $spacing-xs;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}
