@import 'styles/shared.scss';

$navigation-width: 400px;
$animation-time: 1s;
$main-width: calc(100% - #{$navigation-width});
@include fade-from-left(#{$navigation-width});
@include fade-from-right(#{$main-width});

.container {
    display: flex;
    height: 100%;
}

@include is-screen-smaller-than(sm) {
    .container {
        .navigation {
            max-width: 500px;
        }
    }
}

@include is-screen-smaller-than(xxs) {
    .container {
        display: flex;
        .navigation {
            width: 100%;
            min-width: 320px;
            max-width: 500px;
        }
    }
}
