@import 'styles/index.scss';

.container {
    margin-top: $spacing-md;
    width: 100%;
    border-radius: $border-radius-rounded;
    font-size: $font-size-md;
    font-weight: bold;
    padding-left: 14px;
    padding-right: 14px;
    box-shadow: 0 2px 4px $box-shadow-color;

    &:hover {
        cursor: pointer;
    }
}

.primary {
    background-color: $primary-color;
    border: 0px;
    color: white;
    padding-top: 14px;
    padding-bottom: 14px;

    &.disabled {
        color: rgb(218, 218, 218);
        background-color: gray;
    }
}

.secondary {
    background: white;
    border: 0px;
    border-style: solid;
    border-color: $primary-color;
    color: $primary-color;
    padding-top: 14px;
    padding-bottom: 14px;

    &.disabled {
        color: gray !important;
        background-color: rgb(218, 218, 218);
        border-color: gray;
    }
}

.disabled {
    &:hover {
        cursor: not-allowed;
    }
}
