@import 'styles/shared.scss';

.text {
    text-align: justify;
    font-size: $font-size-sm;
    font-weight: 500;
}

.button {
    margin-bottom: $spacing-xs;
}

.websiteLink {
    color: $active-color;
    &:hover,
    &:visited,
    &:focus {
        color: $active-color;
    }
}

.textButton {
    margin: 0px;
    &:hover {
        background-color: transparent;
    }
}

.checkboxes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .checkbox {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: $spacing-xs;
        margin-bottom: $spacing-xs;

        span {
            padding-left: $spacing-xs;

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.loading {
    display: flex;
    align-items: center;
    margin: 10px 0px;

    > span {
        border-color: $font-color;
        border-bottom-color: transparent;
    }

    .loadingText {
        font-size: $font-size-sm;
        margin: 1px 0px 0px 10px;
    }
}
