@import 'styles/shared.scss';

.text {
    margin: $spacing-sm 0;
    font-weight: 500;
    @include user-select-none;
}

.form {
    flex: 1;

    .input {
        margin: $spacing-sm 0;
        input {
            border-radius: $border-radius-rounded !important;
            text-align: center;
        }
    }
}

.button {
    margin: $spacing-sm 0;
    font-weight: 500;
}
