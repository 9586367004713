@import 'styles/index.scss';

.iconButton {
    color: #ffffff;

    &:hover {
        cursor: pointer;
    }
}

.container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: $z-index-fullscreen-media;
    display: flex;
    flex-direction: column;

    &.hidden {
        height: 0;
        width: 0;
        display: none;
    }

    .actions {
        height: 10vh;
        max-height: 10vh;
        display: flex;
        justify-content: flex-end;

        .close {
            margin-top: $spacing-md;
            margin-right: $spacing-md;
        }
    }

    .cursorContainer {
        height: 70vh;
        max-height: 70vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        @include user-select-none;

        &.create {
            height: 50vh;
            max-height: 50vh;
        }

        .arrowContainer {
            flex-basis: 25%;
            display: flex;
            justify-content: center;
            align-items: center;

            .arrow {
                color: #fff;
                &:hover {
                    cursor: pointer;
                }
            }
        }

        .imageContainer {
            background-color: #000;
            height: 70%;
            flex-basis: 80%;
            border-radius: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            @include user-select-none;

            & > *:first-child {
                object-fit: contain;
                max-height: 100%;
                max-width: 100%;
            }

            .cursor {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                user-select: none;
                object-fit: contain;
                max-height: 100%;
                max-width: 100%;
            }
        }
    }

    .message {
        height: 15vh;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 2px solid $border-color;

        .input {
            width: 50%;
        }
    }

    .previewList {
        display: flex;
        align-items: center;
        height: 30vh;
        max-height: 30vh;

        &.create {
            height: 20vh;
            max-height: 20vh;
        }
    }

    .sendButton {
        bottom: $spacing-lg;
        right: $spacing-lg;
        padding: 0 $spacing-lg;
    }
}

@include is-screen-smaller-than(xs) {
    .container {
        .cursorContainer {
            height: 70vh;
            max-height: 80vh;
            flex-direction: column;
        }

        .message {
            .input {
                width: 80%;
            }
        }
    }
}
