@import 'styles/shared.scss';

.container {
    display: flex;
    height: 100%;

    .navigation {
    }

    .main {
        flex: 1;
        display: flex;
        flex-direction: column;

        .header {
            @include basic-header;
        }

        .content {
            border-left: 0.5px solid $box-shadow-color;
            flex: 1;

            h3,
            p,
            .button {
                margin: $spacing-lg;
            }

            p {
                max-width: 350px;
                // text-align: justify;
            }

            .button {
                margin-top: 0;
                width: 250px;
            }
        }
    }
}
