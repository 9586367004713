@import 'styles/index.scss';

.navigation {
    flex: 1;
    background-color: #fff;
    padding: $spacing-sm $spacing-md $spacing-md $menu-width;
    border-right: 1px solid $box-shadow-color;
    margin-top: 0;
    @include user-select-none;
}
