@import 'styles/index.scss';

.chats {
    overflow-y: auto;
    overflow-x: hidden;
    flex-grow: 1;
    @include user-select-none;

    padding: 0 0 $spacing-md $menu-width;

    list-style-type: none;
    margin: 0;

    li.loadingSpinner {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: $spacing-lg;
    }
}
