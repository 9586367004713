@import 'styles/index.scss';

.imageContainer {
    height: 400px;
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius;
    overflow: hidden;

    img {
        height: 400px;
        width: 400px;
        object-fit: cover;
        border-radius: $border-radius;
    }

    .imageHidden {
        display: 'none';
    }

    &:hover {
        cursor: pointer;
    }
}

.videoWrapper {
    width: 400px;
    height: 400px;
    position: relative;
    display: flex;
    justify-content: center;
    border-radius: $border-radius;
    align-items: center;
    overflow: hidden;

    &:hover {
        cursor: pointer;
    }

    .playIcon {
        position: absolute;
        text-align: center;
        margin: auto;
        color: #ffffff;
        fill: #ffffff;
    }

    video {
        border-radius: $border-radius-rounded;
        height: 400px;
        width: 400px;
        object-fit: cover;
    }

    .videoInfo {
        position: absolute;
        left: $spacing-xxs;
        bottom: $spacing-xxs;
        background: rgba(0, 0, 0, 0.5);
        border-radius: $border-radius;
        color: #ffffff;
        fill: #ffffff;
        display: flex;
        align-items: center;
        font-size: $font-size-xs;
        padding: $spacing-xxxs $spacing-xs;

        span {
            margin-left: $spacing-xs;
        }
    }
}

@include is-screen-smaller-than(sm) {
    .imageContainer {
        height: 250px;
        width: 250px;

        img {
            height: 250px;
            width: 250px;
        }
    }

    .videoWrapper {
        height: 250px;
        width: 250px;

        video {
            max-height: 300px;
            object-fit: cover;
        }
    }
}
