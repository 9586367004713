@import 'styles/index.scss';

.container {
    margin-bottom: $spacing-xs;
    margin-left: $spacing-xxs;
    margin-right: $spacing-xxs;

    @include tooltip;

    .consultant {
        font-size: $font-size-md;
        margin-top: $spacing-xs;
        margin-bottom: 4px;

        .employeeItem {
            margin-top: $spacing-sm;
            border-radius: 10px;
            background-color: #ffffff;
            box-shadow: 0 4px 10px $box-shadow-color;
        }

        span {
            font-weight: 500;
        }
    }

    .cancelled {
        flex: 1;
        text-align: center;
        font-style: italic;
        font-size: $font-size-sm;
        margin: $spacing-sm;
    }
}
