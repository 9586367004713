@import 'styles/index.scss';

.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
}

.header {
    display: flex;
    align-items: center;

    img {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        margin-right: $spacing-md;
    }
}

.form {
    @include form;
    margin-top: $spacing-xl;
    flex: 1;
    width: 60%;
    max-width: 400px;
    max-height: 400px;
}
