@import 'styles/shared.scss';

.row {
    margin-top: $spacing-md;
    margin-bottom: $spacing-md;
    input {
        border-radius: $border-radius-rounded !important;
    }
}

.button {
    margin: $spacing-sm 0;
}

.legend {
    margin: $spacing-sm 0;
}
