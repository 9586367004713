@import 'styles/shared.scss';

.container {
    @include customer-profile-media-preview-container;

    &.videoContainer {
        > div {
            height: 100%;
            width: 100%;

            video {
                object-fit: cover;
                border-radius: $border-radius;
            }
        }
    }
}
