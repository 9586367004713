@import 'styles/index.scss';

.container {
    justify-self: end;
    font-style: italic;

    &.missing {
        color: rgb(137, 0, 0);
    }

    &.incomplete {
        color: rgb(137, 135, 0);
    }

    &.complete {
        color: rgb(37, 137, 0);
    }
}
