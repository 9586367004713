@import 'styles/index.scss';

.header {
    @include basic-header;
    align-items: center;
    font-weight: 700;
    padding-left: $spacing-md;
    display: none;
}

.content {
    background-color: #fff;
    flex: 1;
    height: 100%;
}

@include is-screen-smaller-than(sm) {
    .splitScreenRightContainer {
        z-index: $z-index-mobile-splitscreen;
        padding-top: 80px;

        .header {
            display: flex;
            position: sticky;
            top: 0;
            padding-left: 70px;
            width: 100%;
            height: 100%;
            z-index: $z-index-mobile-splitscreen-right-header;
        }
        .content {
            padding-top: 0px;
            width: 100%;
            height: calc(100% - #{$header-height-with-padding});
            overflow: auto;
            z-index: $z-index-mobile-splitscreen-right;
        }
    }
}
