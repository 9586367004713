@import 'styles/shared.scss';

$back-icon-size: 36px;

.header {
    flex: 1;
    position: relative;
    align-self: stretch;
    display: flex;
    justify-content: center;

    .backIcon {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;

        background-color: #fff;
        border-radius: 50%;
        height: $back-icon-size;
        width: $back-icon-size;
        box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);

        &:hover {
            cursor: pointer;
        }
    }

    h4 {
        font-size: 1.1rem;
        font-weight: 900;
        white-space: nowrap;
    }
}
