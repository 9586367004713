@import 'styles/shared.scss';

.form {
    flex: 1;
    @include user-select-none;

    .input {
        margin: $spacing-sm 0;
        input {
            border-radius: $border-radius-rounded !important;
        }
    }
}

.button {
    margin: $spacing-sm 0;
}

.legend {
    margin: $spacing-sm 0;
}
