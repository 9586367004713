@import 'styles/shared.scss';

.card {
    width: 275px;
    align-items: center;
    padding: $spacing-lg;

    .image {
        margin-top: $spacing-md;
    }

    .companyName {
        font-weight: 600;
        font-size: 1.15rem;
        margin: $spacing-xl 0;
    }

    .button {
        font-weight: 400;

        &:first-of-type {
            margin-top: 0;
        }
    }

    .hint {
        font-size: $font-size-sm;
    }
}
