@import 'styles/index.scss';

.employee {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $spacing-xs;
    @include user-select-none;
    position: relative;

    &.active {
        background-color: #eceff2;
        @include is-screen-smaller-than(sm) {
            background-color: transparent;
        }
    }

    &:hover {
        cursor: pointer;
        background-color: $background-light;
    }

    .text {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: $spacing-xs;

        .name {
            font-weight: 700;
            color: #000000;
            padding-left: $spacing-xxxs;
        }

        .jobTitle {
            color: $font-color;
            padding-left: $spacing-xxxs;
            font-size: $font-size-sm;
        }

        .onlineStatus {
            font-size: $font-size-sm;
        }
    }

    .invitationIcon {
        padding-top: 4px;
        padding-right: 34px;
    }

    .contextMenu {
        position: absolute;
        margin-left: $spacing-xxxs;
        right: 0px;
        top: 22px;
        height: 24px;
        width: 30px;
        opacity: 0;
        transition: right 0.1s ease-in-out, opacity 0.2s ease-in-out;
        z-index: 1000;
    }

    &:hover {
        .contextMenu {
            right: 0px;
            opacity: 1;
        }
    }
}
