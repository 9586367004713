@import 'styles/index.scss';

.company {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $spacing-xs;
    padding-right: $spacing-sm;
    overflow: hidden;
    margin: 0;
    @include user-select-none;

    .text {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: $spacing-xs;

        .name {
            font-weight: 700;
            color: #000000;
            padding-left: $spacing-xxxs;
        }
    }
}
