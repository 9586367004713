@import 'styles/index.scss';

.menuEntry {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: $spacing-xs;
    padding-bottom: $spacing-xs;
    position: relative;

    &:hover {
        &:not(:first-child) {
            cursor: pointer;
            background-color: $primary-color;
        }

        &:first-child {
            border-top-right-radius: $menu-border-radius;
        }
    }

    svg {
        fill: #ffffff;
    }

    span {
        margin-top: 0.25rem;
        font-size: $font-size-xxs;
    }

    .messageCount {
        position: absolute;
        background-color: $animal-red;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 33%;
        right: 27%;
        font-size: $font-size-xxs;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid #ffffff;
        z-index: 3;
    }

    &.active {
        background-color: $active-overlay-color;

        &:first-child {
            border-top-right-radius: $menu-border-radius;
        }

        svg {
            fill: $animal-red;
        }

        .messageCount {
            background-color: $animal-red;
            color: #ffffff;
        }
    }
}
