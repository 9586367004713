@import 'styles/index.scss';

$preview-item-size: 96px;
$image-size: $preview-item-size !important;

.iconButton {
    color: #ffffff;

    &:hover {
        cursor: pointer;
    }
}

.container {
    flex: 1;
    height: 20vh;
    max-height: 20vh;
    display: flex;
    max-width: 100%;
    overflow-x: auto;

    .previewList {
        padding: 0;
        margin: 0;
        list-style-type: none;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: $spacing-md;
        padding-left: $spacing-md;
        padding-right: $spacing-md;

        li {
            height: calc(#{$preview-item-size} + 4px);
            width: calc(#{$preview-item-size} + 4px);
            position: relative;
        }

        .preview {
            height: $image-size;
            width: $image-size;
            object-fit: cover;
            border: 2px solid transparent;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.selected {
                height: $image-size;
                width: $image-size;
                border-color: gray;
            }

            &:hover {
                cursor: pointer;
                border-color: gray;
            }
        }
        .videoContainer {
            height: $image-size;
            width: $image-size;
            position: relative;
            video {
                object-fit: cover;
            }
        }

        .videoInfo {
            position: absolute;
            left: $spacing-xxxs;
            bottom: $spacing-xxxs;
            color: #ffffff;
            fill: #ffffff;
            display: flex;
            align-items: center;
            font-size: $font-size-xxs;
            padding: $spacing-xxxs $spacing-xs;

            span {
                margin-left: $spacing-xxxs;
            }
        }

        .addImage {
            height: $image-size;
            width: $image-size;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                cursor: pointer;
            }
        }
    }
}
