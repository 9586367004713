@import 'styles/index.scss';

$gradient-degree: 45deg;

.list {
    margin: 0;
    padding: 0;
    min-width: 324px;
    list-style-type: none;

    li {
        border-bottom: 1px solid $box-shadow-color-light;
    }

    .addEntry {
        display: flex;
        justify-content: center;
        padding-top: $spacing-md;
        padding-bottom: $spacing-md;
        font-weight: 500;
        color: $active-color;

        &:hover {
            cursor: pointer;
            background-color: $background-light;
        }
    }

    .emptyEntry {
        height: 100px;
        display: flex;

        .text {
            flex-grow: 6;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: $spacing-xs;

            .title {
                margin: $spacing-xxs;
                max-width: 80%;
                height: 20px;
                background: $placeholder-title;
                background: linear-gradient(
                    $gradient-degree,
                    $placeholder-title 0%,
                    $placeholder-title-gradient 100%
                );
            }

            .subTitle {
                margin: $spacing-xxs;
                max-width: 60%;
                height: 15px;
                background: $placeholder-sub-title;
                background: linear-gradient(
                    $gradient-degree,
                    $placeholder-sub-title 0%,
                    $placeholder-sub-title-gradient 100%
                );
            }
        }

        .profile {
            margin-right: $spacing-sm;
            display: flex;
            align-items: center;
            justify-content: center;

            .image {
                background: $placeholder-title;
                background: linear-gradient(
                    $gradient-degree,
                    $placeholder-title 0%,
                    $placeholder-title-gradient 100%
                );
                height: 60px;
                width: 60px;
                border-radius: 50%;
            }
        }
    }

    .entry {
        height: 100px;
        display: flex;

        .text {
            flex-grow: 6;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: $spacing-xs;

            .title {
                margin: $spacing-xxs;
                font-weight: 500;
                height: 20px;
            }

            .subTitle {
                margin: $spacing-xxs;
                font-size: $font-size-sm;
                height: 15px;
                color: $font-color-sub;
            }

            .cancelled {
                color: $animal-red;
            }
        }

        .profile {
            margin-right: $spacing-sm;
            display: flex;
            align-items: center;
            justify-content: center;

            .image {
                background: $placeholder-title;
                background: linear-gradient(
                    $gradient-degree,
                    $placeholder-title 0%,
                    $placeholder-title-gradient 100%
                );
                height: 60px;
                width: 60px;
                border-radius: 50%;
            }
        }

        &.active {
            background-color: $background-light;
        }

        &:hover {
            cursor: pointer;
            background-color: $background-light;
        }
    }

    .hide {
        display: none;
    }
}
