@import 'styles/index.scss';

.text {
    margin: 0;
    padding-top: $spacing-xxxs;
    padding-left: $spacing-xxxs;
    padding-right: $spacing-xxxs;
    word-wrap: break-word;
}

.bigText {
    margin: 0;
    padding-top: $spacing-xxxs;
    padding-left: $spacing-xxxs;
    padding-right: $spacing-xxxs;
    word-wrap: break-word;
    font-size: 40px;
}

.timeSlot {
    font-weight: 600;
    font-size: $font-size-md;
    margin-top: $spacing-xs;
    padding-right: $spacing-xxxs;
}

.price {
    font-size: $font-size-md;
    font-weight: 600;
    margin-top: $spacing-xs;
    margin-right: $spacing-sm;
    padding-right: $spacing-xxxs;
}

.statusText {
    margin-top: $spacing-xs;
    padding-left: 2px;
    padding-right: 2px;

    .statusValue {
        font-weight: 600;

        &.paid {
            color: green;
        }

        &.pending {
            color: orange;
        }
    }
}

.closedOrder {
    font-size: $font-size-sm;
    font-style: italic;
    color: $font-color;
    margin-top: $spacing-xs;
    padding-left: 2px;
    padding-right: 2px;
}

.cancelled {
    font-size: $font-size-sm;
    font-style: italic;
    color: red;
    margin-top: $spacing-xs;
    padding-right: 2px;
}
