@import 'styles/index.scss';

.container {
    -webkit-user-select: none;
    user-select: none;
    -webkit-app-region: drag;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 300px;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;

    &.loading {
        justify-content: center;
        @include fade-spinner-color;
    }
}

.header {
    display: flex;
    align-items: center;

    img {
        padding-top: 50px;
        padding-bottom: 20px;
        height: 40px;
        border-radius: 5%;
        margin-right: $spacing-md;
    }
}

.form {
    @include form;
    position: relative;
    -webkit-app-region: no-drag;
    width: 70%;
    max-width: 400px;
    max-height: 350px;
    background-color: $background-light;
    margin-bottom: 50px;

    .formOverlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 5;
        border-radius: $border-radius-rounded;

        &.loadingSpinner {
            @include fade-spinner-color;
        }
    }

    label {
        display: inline-block;
        margin-bottom: $spacing-xs;
    }

    .input {
        margin-bottom: $spacing-md;
    }

    .buttons {
        margin-top: $spacing-md;
        margin-bottom: $spacing-md;
    }

    .passwordActions {
        display: flex;
        justify-content: flex-end;

        a,
        a:active,
        a:visited,
        a:hover,
        a:focus {
            color: $primary-color;
        }
    }
}
