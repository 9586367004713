@import 'styles/index.scss';

.container {
    padding: $spacing-md;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    min-width: 300px;
    overflow-x: hidden;

    a,
    a:active,
    a:visited {
        text-decoration: none;
        color: $primary-color;
    }
}
