// chat-range: 0-100
$z-index-chat: 0;
$z-index-date-badge: 10;
$z-index-appointment-datepicker: 11;
$z-index-chat-animal-header: 20;
$z-index-close-request: 38;
$z-index-scroll-to-bottom: 39;
$z-index-chat-detail: 40;

// settings;
$z-index-settings-container: 200;

// userProfile
$z-index-user-profile: 200;

// menu
$z-index-menu: 300;
$z-index-system-banner: 300;

// navigation
$z-index-nav: 440;

// mobile main
$z-index-mobile-main: 400;

// overlay
$z-index-fullscreen-media: 400;

// mobile main header
$z-index-mobile-main-header: 420;

// mobile splitscreen
$z-index-mobile-splitscreen: 430;
$z-index-mobile-splitscreen-left: 430;
$z-index-mobile-splitscreen-right: 430;

// mobile splitscreen header
$z-index-mobile-splitscreen-right-header: 440;

// navigation bar
$z-index-nav-bar: 441;

// chat entry context menu
$z-index-chat-entry-context-menu: 450;

// modal
$z-index-modal: 800;

// update banner
$z-index-update-banner: 900;

// alerts
$z-index-alert: 999;
