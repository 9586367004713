@import 'styles/index.scss';

$image-size: 200px;

.container {
    padding: $spacing-xl;
    position: relative;

    .fileInput {
        visibility: hidden;
        display: none;
    }

    .profile {
        position: absolute;
        top: 2rem;
        left: 2rem;
        height: $image-size;
        width: $image-size;

        &:hover {
            cursor: pointer;
            .text {
                opacity: 1;
            }
        }

        .text {
            opacity: 0.8;
            border-radius: 10px;
            position: absolute;
            width: 80%;
            top: 50%;
            left: 51%;
            transform: translate(-50%, -50%);
            color: #ffffff;
            font-weight: 500;
            font-size: $font-size-sm;
            background: rgba(0, 0, 0, 0.8);
            padding: $spacing-xs;
            text-align: center;
        }

        img {
            width: 100%;
            height: 100%;
            border-radius: calc(#{$image-size} / 2);
            border: 2px solid $border-color;
        }

        .spinner {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            @include spinner-color;
        }
    }
}

@include is-screen-smaller-than(xxs) {
    .container {
        position: unset;
        display: flex;
        flex: 1;
        justify-content: center;

        .profile {
            position: unset;
            display: flex;
            align-self: flex-start;
            height: 300px;

            .text {
                width: 200px;
                top: 340px;
            }
        }
    }
}
