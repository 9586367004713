@import 'styles/index.scss';

$transition-time: 0.2s;
$switch-size: 25px;

.label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50px;
    height: 30px;
    background: grey;
    border-radius: 30px;
    position: relative;
    transition: background-color $transition-time;

    &:hover {
        cursor: pointer;
    }

    &.checked {
        background-color: $active-color;
    }

    .button {
        position: absolute;
        top: 2px;
        left: 2px;
        width: $switch-size;
        height: $switch-size;
        border-radius: calc(#{$switch-size} / 2);
        transition: $transition-time;
        background: #fff;

        &.checked {
            transition: $transition-time;
            left: calc(100% - 2px);
            transform: translateX(-100%);
        }
    }

    .checkbox {
        height: 0;
        width: 0;
        visibility: hidden;
    }

    &.disabled {
        &:hover {
            cursor: not-allowed;
        }

        &.checked {
            background-color: $switch-background-disabled;
        }

        .button {
            background-color: $switch-disabled;
        }
    }
}
