@import 'styles/shared.scss';

.card {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    min-width: 350px;
    padding: $spacing-sm;
    border: 1px solid $border-color;
    border-radius: $border-radius-rounded;
    background-color: $background-extra-light;
    -webkit-box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.45);
    box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.25);
}

@include is-screen-smaller-than(xs) {
    .card {
        min-width: 200px;
        width: 90%;
    }
}
