@import 'styles/shared.scss';

$business-header-height: 30px;
$header-padding: $spacing-xl;
$menu-breakpoint: 899px;
$nav-width: 300px;

.container {
    display: flex;
    flex-direction: column;

    header {
        @include user-select-none;
        display: flex;
        flex-direction: row;
        padding: $header-padding;
        height: $business-header-height;
        display: flex;
        align-items: center;
        z-index: $z-index-nav-bar;
        -webkit-box-shadow: 0px 2px 12px 1px rgba(0, 0, 0, 0.45);
        box-shadow: 0px 2px 12px 1px rgba(0, 0, 0, 0.45);
        background-color: white;
        overflow: hidden;
        -webkit-app-region: drag;

        .menuToggle {
            display: none;
            justify-content: flex-start;

            @media only screen and (max-width: $menu-breakpoint) {
                display: flex;
                justify-content: center;
                margin-right: $spacing-sm;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .logo {
            justify-content: flex-start;
            height: 100%;
            max-height: 22px;
            padding-left: 4px;
            padding-top: 4px;
            &:hover {
                cursor: pointer;
            }
        }

        h2 {
            flex: 1;
            margin-left: $spacing-sm;
        }

        .link {
            text-align: right;
            justify-content: flex-end;
            flex: 1;

            color: $primary-color;
            font-weight: 600;
            font-size: 1.15rem;

            &:hover {
                cursor: pointer;
            }
        }

        .profileIcon {
            padding-left: 14px;
            display: flex;
            justify-content: flex-end;
            color: $font-color-sub;
            transition: color 0.2s ease-in-out;

            &:hover {
                cursor: pointer;
            }
        }
    }
}
