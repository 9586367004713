@import 'styles/index.scss';

$chat-detail-width: 400px;
$small-chat-column-width: 500px;
$media-preview-item-size: 130px;

@mixin customer-profile-media-preview-container {
    height: $media-preview-item-size;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(rgb(25, 25, 25), rgb(52, 52, 52));
    border-radius: $border-radius;

    &.clickable:hover {
        cursor: pointer;
    }

    &.loadingSpinner {
        > span {
            > span {
                background-color: #fff;
            }
            margin-left: 10px; // = (18 + FadeSpinner.margin) / 2
        }
    }
}

@mixin fade-from-left($nav-width) {
    @keyframes fade-from-left {
        from {
            left: calc(-1 * #{$nav-width});
        }
        to {
            left: 0;
        }
    }
}

@mixin fade-from-right($main-width) {
    @keyframes fade-from-right {
        from {
            right: calc(-1 * #{$main-width});
        }
        to {
            right: 0;
        }
    }
}
