@import 'styles/index.scss';

.container {
    width: 101px;
    align-self: center;
    font-size: $font-size-sm;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius-rounded;
    background-color: $background-light;
    padding-left: $spacing-sm;
    padding-right: $spacing-sm;
    padding-top: $spacing-xxxs;
    padding-bottom: $spacing-xxxs;
    margin-top: $spacing-xs;
    margin-bottom: $spacing-xs;
    position: sticky;
    top: 72px;
    z-index: $z-index-date-badge;

    &.noHeaderPadding {
        top: 0px;
    }
}
