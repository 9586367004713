@import 'styles/shared.scss';

.container {
    padding: 20px;
    padding-bottom: 180px;

    .card {
        align-items: center;
        padding: $spacing-lg;
        min-width: 240px;
        max-width: 480px;

        .logo {
            height: 100px;
            width: 100px;
            margin-bottom: $spacing-lg;
        }
    }
}
