@import 'styles/index.scss';

$padding: 50px;

.container {
    @include box-shadow-strong();
    z-index: $z-index-update-banner;
    position: fixed;
    left: 0;
    top: -100%;
    width: 100%;
    background-color: $animal-yellow;
    transition: top 0.4s;

    .textContainer {
        p {
            margin-top: 0;
        }

        padding-top: $padding;
        padding-bottom: $padding;
        padding-left: $padding;
        padding-right: 115px;
    }

    &.error {
        background-color: $animal-red;
        color: #fff;
    }

    .restart {
        margin-top: $spacing-xs;
        text-decoration: underline;
        transition: color 0.2s;

        &:hover {
            cursor: pointer;
            color: $font-color-sub;
        }

        &.error {
            color: #fff;
            &:hover {
                color: rgb(230, 230, 230);
            }
        }
    }

    .closeBtn {
        position: absolute;
        right: $padding;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid $font-color;
        border-radius: 50%;

        &.error {
            border-color: #fff;
        }

        &:hover {
            cursor: pointer;
        }
    }
}
