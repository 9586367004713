@import 'styles/zindex.scss';
@import 'styles/mediaQueries.scss';

$primary-color: #1f6ac3;
$background-blue-dark: #1f6ac3;
$background-blue-dark-gradient: #1b5faf;
$background-blue-darker: #0f3561;
$background-dark: #202428;
$background-light: #eceff2;
$background-extra-light: #f6f4f1;
$background-chat: #e3dcd5;
$background-chat-gradient: #e4d9ce;

$button-disabled: #7b7c7d;
$switch-disabled: #eaeaea;
$switch-background-disabled: #5788c0;

$font-color: #2c3e50;
$font-color-sub: #8a8a8e;

$placeholder-title: #b9b9b9;
$placeholder-title-gradient: #dddddd;
$placeholder-sub-title: #d4d4d4;
$placeholder-sub-title-gradient: #ececec;

$animal-gray: #767677;
$animal-dark-brown: #916e63;
$animal-beige-brown: #d2a56d;
$animal-pink: #f7adb9;
$animal-yellow: #ffe65c;
$animal-green: #98c776;
$animal-red: rgb(255, 88, 82);

$error-color: rgb(190, 9, 3);
$warn-color: #ffe65c;
$success-color: rgb(0, 138, 41);

$border-color: #e0e0e0;
$border-color-darker: #ababab;

$font-family: Avenir, Helvetica, Arial, sans-serif;

$font-size-xxxs: 0.6rem;
$font-size-xxs: 0.675rem;
$font-size-xs: 0.8rem;
$font-size-sm: 0.95rem;
$font-size-md: 1rem;
$font-size-lg: 1.25rem;
$font-size-xl: 1.5rem;

$spacing-xxxs: 0.25rem;
$spacing-xxs: 0.35rem;
$spacing-xs: 0.5rem;
$spacing-sm: 0.75rem;
$spacing-md: 1rem;
$spacing-lg: 1.25rem;
$spacing-xl: 1.5rem;
$spacing-xxl: 2rem;
$spacing-xxxl: 4rem;

$profile-image-size: 48px;

$border-radius: 5px;
$border-radius-rounded: calc(#{$border-radius} * 2);
$border-radius-very-rounded: calc(#{$border-radius} * 4);

// $box-shadow-color: rgba(0, 0, 0, 0.19);
$box-shadow-color: rgba(151, 151, 151, 0.19);
$box-shadow-color-light: rgba(151, 151, 151, 0.19);
$active-overlay-color: #1b5faf;
$active-color: #2a71f7;

$header-padding: $spacing-xs;
$header-height: 50px;
$header-height-with-padding: calc(
    #{$header-height} + #{$header-padding} + #{$header-padding}
);

$menu-width: 90px;
$chat-navigation-width: 400px;

$icon-size-default: 24px; // keep in sync with icon.tsx

@mixin sub-menu-spacing {
    padding: $spacing-md $spacing-md $spacing-md
        calc(#{$spacing-md} + #{$menu-width});
}

@mixin form {
    box-shadow: 0 2px 8px $box-shadow-color;
    border-radius: $border-radius-rounded;
    padding: $spacing-md;
}

@mixin box-shadow-strong {
    -webkit-box-shadow: 3px 3px 12px 1px rgba(0, 0, 0, 0.45);
    box-shadow: 3px 3px 12px 1px rgba(0, 0, 0, 0.45);
}

@mixin basic-header {
    min-height: $header-height;
    max-height: $header-height;
    background-color: $background-light;
    display: flex;
    padding: $header-padding;
    border: 0.5px solid $box-shadow-color;

    -webkit-user-select: none;
    user-select: none;
    -webkit-app-region: drag;
}

@mixin spinner-color {
    span {
        border-color: $primary-color;
        border-bottom-color: transparent;
    }
}

@mixin spinner-color-dark {
    span {
        border-color: $background-light;
        border-bottom-color: transparent;
    }
}

@mixin fade-spinner-color {
    span > span {
        background-color: #fff;
    }
}

@mixin tooltip {
    .tooltip {
        background-color: #000000;
        font-weight: 500;
    }
}

$menu-border-radius: calc(2 *#{$border-radius-rounded});

@mixin user-select-none {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

img {
    @include user-select-none;
}
