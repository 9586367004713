@import 'styles/index.scss';

.container {
    padding: $spacing-sm $spacing-xl;
    max-width: 500px;
    flex: 1;

    .title {
        color: #000000;
        font-weight: 700;
    }

    .actions {
        display: flex;
        gap: $spacing-sm;
        align-items: center;
        justify-content: flex-end;
        @include user-select-none;
    }
}
