@import 'styles/index.scss';

.container {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    padding: 20px;
    padding-bottom: 180px;
    min-width: 260px;

    .row {
        display: flex;
        align-items: center;
        padding-bottom: $spacing-xl;

        .field {
            flex: 1 1;
            width: 90%;
            display: flex;
            flex-direction: column;
            font-size: $font-size-md;

            &.companyName {
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: $font-size-lg;
                font-weight: 700;
                margin: 0;
                display: block;
            }

            p {
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
                margin: 0;
                padding-bottom: $spacing-xs;
            }

            .name {
                font-weight: 700;
                color: $primary-color;
                font-size: $font-size-md;
            }
        }

        .editIconContainer {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            flex: 1;

            .editIcon {
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}
