@import 'styles/index.scss';

.navHeader {
    @include basic-header;
    align-items: center;
    justify-content: flex-start;
    padding-left: 100px;

    .practice {
        margin-left: $spacing-md;
        width: 100%;

        p {
            margin: 0;
            font-weight: 700;
        }

        span {
            font-size: $font-size-sm;
        }

        .subTitleContainer {
            display: flex;

            .subTitle {
                flex: 1;
            }
        }

        .addIcon {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: #ffffff;
            color: $active-color;

            &:hover {
                cursor: pointer;
            }
        }

        .medianResponseTimeBadgeContainer  {
            margin-left: 4px;
            flex: 1;
            flex-direction: row;
        }

        .medianResponseTimeBadge {
            justify-self: flex-end;
            margin-left: 10px;
            font-size: $font-size-xs;
            background-color: $background-blue-darker;
            border-radius: $border-radius;
            color: #fff;
            font-weight: 500;
            text-align: center;
            white-space: nowrap;
            padding-left: 6px;
            padding-right: 6px;
            padding-top: 2px;
            padding-bottom: 2px;
        }
    }
}
