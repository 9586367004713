@import 'styles/index.scss';

.container {
    @include basic-header;
    box-shadow: 0 2px 4px $box-shadow-color;
    display: flex;
    align-items: center;
    overflow-x: hidden;
    position: 'sticky';
    top: 0px;
    right: 0px;
    left: 300px;

    ion-icon {
        padding-left: 10px;
        &:hover {
            cursor: pointer;
        }
    }

    .title {
        margin-left: $spacing-lg;
        margin-right: $spacing-sm;

        color: #000000;
    }
}
