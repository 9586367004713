@import 'styles/index.scss';

.container {
    position: sticky;
    top: 0;
    left: 0;
    z-index: $z-index-chat-animal-header;
    display: grid;
    // grid-template-columns: 1fr fit-content(20%);
    // grid-template-rows: auto auto;
    // justify-content: center;

    align-self: stretch;
    @include user-select-none;

    .animalInfo {
        display: flex;
        align-items: center;
        padding: $spacing-xs;
        padding-right: $spacing-xl;
        border-bottom-left-radius: $border-radius-rounded;
        border-bottom-right-radius: $border-radius-rounded;
        background-color: #ffffff;
        box-shadow: 0 2px 4px $box-shadow-color;
        z-index: $z-index-chat-animal-header;

        &:hover {
          cursor: pointer;
          background-color: $background-light;
        }

        .animalDetail {
            padding-left: $spacing-sm;
            display: flex;
            flex-direction: column;

            .name {
                font-weight: 700;
                color: #000000;
            }

            .details {
                font-size: $font-size-sm;
            }
        }
    }

    .actions {
        display: flex;
        align-items: center;
        padding-right: $spacing-lg;
        padding-left: $spacing-lg;
        background-color: rgba(228, 235, 248, 0.7);
        border-bottom-right-radius: $border-radius-rounded;
    }

    &.hidden {
        display: none;
    }
}
