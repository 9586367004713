@import 'styles/index.scss';

$image-size: 200px;

.profileContainer {
    position: relative;
    margin-top: $spacing-md;
    grid-column-start: span 2;
    height: $image-size;
    width: $image-size;
    margin-bottom: $spacing-md;

    .fileInput {
        height: 0px;
        width: 0px;
        visibility: hidden;
        display: none;
    }

    .profile {
        position: absolute;
        height: $image-size;
        width: $image-size;
        border-radius: 50%;

        &:hover {
            cursor: pointer;
            .text {
                opacity: 1;
            }
        }

        .text {
            opacity: 0.8;
            border-radius: 10px;
            position: absolute;
            width: 80%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #ffffff;
            font-weight: 500;
            font-size: $font-size-sm;
            background: rgba(0, 0, 0, 0.8);
            padding: $spacing-xs;
            text-align: center;
        }

        img {
            width: 100%;
            height: 100%;
            border-radius: calc(#{$image-size} / 2);
        }

        .spinner {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 1px;
            left: 1px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.8);
            border: 1px solid rgba(0, 0, 0, 0.8);
            border-radius: 50%;
            @include spinner-color;

            &:hover {
                cursor: default;
            }

            > span {
                left: 25px;
                > span {
                    background-color: #ffffff;
                }
            }
        }
    }
}
