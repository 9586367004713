@import 'styles/shared.scss';
$animation-time: 0.2s;

.container {
    overflow-x: hidden;
    height: 100%;
    background-color: white;
    position: relative;

    .animalContextList {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        height: 100%;
        width: 100%;
        list-style: none;
        margin: 0px;
        padding: 0px;

        > li:last-of-type {
            padding-bottom: 100px;
        }
    }
}
