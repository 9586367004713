@import 'styles/index.scss';

.container {
    .menuContainer {
        position: absolute;
        padding: 0;
        border-radius: $border-radius-rounded;
        background-color: ghostwhite;
        z-index: $z-index-chat-entry-context-menu;
        box-shadow: 0 2px 10px gray;

        display: block;
        opacity: 1;
        height: auto;
        width: auto;
        max-width: 80vw;
        min-width: 200px;

        > li {
            height: auto;
            width: auto;
            padding: 10px 16px 10px 10px;
            margin: auto;

            &:not(:last-of-type) {
                border-bottom: 1px solid $border-color;
            }

            &:hover {
                cursor: pointer;
                background-color: $background-light;
            }

            &:first-of-type {
                border-top-left-radius: $border-radius-rounded;
                border-top-right-radius: $border-radius-rounded;
            }

            &:last-of-type {
                border-bottom-left-radius: $border-radius-rounded;
                border-bottom-right-radius: $border-radius-rounded;
            }
        }
    }

    .hidden {
        position: absolute;
        padding: 0;
        border-radius: $border-radius-rounded;
        background-color: ghostwhite;
        z-index: $z-index-chat-entry-context-menu;
        box-shadow: 0 2px 10px gray;

        display: none;
        height: 0px;
        width: 0px;
        opacity: 0;

        > li {
            height: 0px;
            width: 0px;
            padding: 0px;
            margin: 0px;
        }
    }
}
