@import 'styles/index.scss';

$scroll-to-bottom-btn-size: 40px;
$transition-time: 0.2s;

.scrollToBottomBtn {
    &:hover {
        cursor: pointer;
    }

    &.visible {
        opacity: 0.7;
        visibility: visible;
    }

    height: $scroll-to-bottom-btn-size;
    width: $scroll-to-bottom-btn-size;
    transition: visibility $transition-time linear,
        opacity $transition-time linear;
    visibility: hidden;
    opacity: 0;
    position: fixed;
    border-radius: 50%;
    bottom: $spacing-md;
    right: $spacing-md;
    background-color: $background-dark;
    z-index: $z-index-scroll-to-bottom;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
