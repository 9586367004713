@import 'styles/index.scss';

$transition-time: 0.2s;

.container {
    &:hover {
        cursor: pointer;
    }

    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: $background-light;
    box-shadow: inset 0 2px 8px $box-shadow-color;

    .header {
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        font-weight: 600;
    }

    .text {
        padding-top: 10px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .button {
        padding: 20px;
        margin: 20px;
        margin-top: 14px;
        background-color: $animal-red;
        border-radius: 20px;
        text-align: center;
        font-weight: 600;
        color: white;
        box-shadow: 0 2px 8px $box-shadow-color;
    }
}
