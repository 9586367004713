@import 'styles/shared.scss';

.container {
    display: flex;
    flex: 1;
    overflow: auto;
    position: relative;

    .splitScreen {
        > div:first-child {
            border-right: none;
        }
    }
}

.spinner {
    flex: 1;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    &:hover {
        cursor: default;
    }

    > span {
        left: 25px;
        top: -200px;
        > span {
            background-color: lightgray;
        }
    }
}
