@import 'styles/index.scss';

$size: 300px;

.container {
    padding: $spacing-xl;
    flex: 1;

    > div {
        display: flex;
        flex-direction: column;
        max-width: $size;
        margin-bottom: $spacing-xl;
    }

    .licenceName {
        padding-top: $spacing-sm;
        padding-bottom: $spacing-sm;
        padding-left: $spacing-md;
        border: 1px solid $border-color-darker;
        border-radius: $border-radius-rounded;
        box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.35);
        color: $font-color;
        font-weight: 500;
        font-size: large;
    }

    .renewalContainer {
        display: flex;
        color: $font-color-sub;
        margin-top: $spacing-md;
        margin-bottom: $spacing-md;

        .renewalText {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            h3 {
                font-weight: 400;
                margin: 0 0 $spacing-xxxs 0;
            }

            h5 {
                margin: 0;

                font-weight: 400;
            }

            .cancelled {
                color: $animal-red;
            }
        }
    }

    .assignmentHeader {
        display: flex;
        color: $font-color-sub;
        margin-top: $spacing-md;
        margin-bottom: $spacing-md;
        align-items: stretch;

        .assignmentLabel {
            flex: 1;
        }

        .remove {
            justify-self: flex-end;
            color: $active-color;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .upgradeContainer {
        span {
            margin-bottom: $spacing-md;
        }

        display: flex;
        flex-direction: column;
        color: $font-color-sub;
        margin-top: $spacing-md;
        margin-bottom: $spacing-md;
    }

    .card {
        max-height: $size;
        max-width: $size;
        min-height: $size;
        min-width: $size;
        border: 1px solid rgb(222, 222, 222);
        border-radius: $border-radius-very-rounded;
        background-color: $background-light;
        box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.35);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: $spacing-xxl;

        .name {
            margin-top: $spacing-md;
            font-weight: 500;
        }
    }
}

.hide {
    display: none !important;
}

@include is-screen-smaller-than(md) {
    .container {
        display: block;
        padding-left: $spacing-md;
        padding-right: $spacing-md;
        align-content: center;
        min-width: 300px;

        > label {
            display: block;
            margin-top: $spacing-md;
            margin-bottom: $spacing-xs;
        }
    }
}
