@import 'styles/mediaQueries.scss';

html,
body,
#root {
    height: 100%;
    width: 100%;
    margin: 0;
    overflow: hidden;

    // important to avoid auto scrolling on input focus in ios
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
}

body {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    color: #2c3e50;

    // Disables default tooltip for Ionicons https://github.com/ionic-team/ionicons/issues/949
    ion-icon:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
    }
}

p {
    line-height: 1.4;
}

@include is-screen-smaller-than(xs) {
    #root {
        width: 100vw;
        max-width: 100vw;
        overflow: hidden;
    }
}

iframe {
    pointer-events: none;
}
