@import 'styles/index.scss';

.listItem {
    position: relative;
    display: flex;
    border-bottom: 1px solid $box-shadow-color-light;
    padding: $spacing-sm $spacing-xxxs $spacing-md $spacing-sm;
    height: 56px;

    .profile {
        margin-top: $spacing-xxs;
        margin-right: $spacing-sm;
    }

    .textHtml {
        top: 0;
        position: absolute;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        width: 100%;
        max-width: calc(80% - 40px);
        min-height: 26px;
    }

    .chatPreview {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-right: $spacing-md;
        padding-bottom: $spacing-xs;
        min-width: 100%;
        width: 100%;

        .profileName {
            color: $background-blue-dark;
            margin: 0;
            font-weight: 500;
            font-size: 0.9rem;
            white-space: nowrap;
            overflow-x: hidden;
            text-overflow: ellipsis;
            width: 100%;
            max-width: calc(80% - 84px);
            min-height: 20px;
        }

        .chatContextName {
            color: #000000;
            margin: 0;
            font-weight: 500;
            font-size: 1.1rem;
            white-space: nowrap;
            overflow-x: hidden;
            text-overflow: ellipsis;
            width: 100%;
            max-width: calc(80% - 74px);
            min-height: 26px;
        }

        .messagePreview {
            font-size: $font-size-sm;
            display: flex;
            align-items: center;

            ion-icon {
                padding-top: 1px;
                margin-right: $spacing-xxxs;
            }
        }
    }

    .checkmark {
        padding-right: 4px;
    }

    .counterBadgeContainer {
        position: absolute;
        right: 0px;

        .counterBadge {
            transition: all 0.1s ease-in-out;
            position: absolute;
            right: 0px;
            top: 50%;
        }

        .contextMenu {
            position: absolute;
            margin-left: $spacing-xxxs;
            right: -24px;
            top: 50%;
            height: 24px;
            width: 24px;
            opacity: 0;
            transition: right 0.1s ease-in-out, opacity 0.2s ease-in-out;
        }

        .tooltip {
            max-width: 250px;
        }
    }

    .chatInfo {
        padding-bottom: $spacing-xs;
        position: absolute;
        right: $spacing-sm;
        top: $spacing-xs;

        & > *:not(:last-child) {
            margin-bottom: 0.25rem;
        }

        .lastMessageTime {
            display: inline;
            font-size: $font-size-xs;
            right: 10px;
            top: 10px;
        }
    }

    &.active {
        background-color: $background-extra-light;
    }

    &:hover {
        cursor: pointer;
        background-color: $background-light;

        .counterBadge {
            &:not(.noLicence) {
                position: absolute;
                right: 28px;
                top: 50%;
            }
        }

        .contextMenu {
            right: 0px;
            opacity: 1;
        }
    }
}
