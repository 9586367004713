@import 'styles/shared.scss';

.cardContainer {
    padding: 20px;
    .card {
        &:first-of-type {
            margin-top: 0px;
        }
    }
}
