@import 'styles/shared.scss';

.container {
    padding-top: $spacing-xxxs;
    padding-bottom: $spacing-xxxs;
    padding-left: $spacing-xxxs;
    padding-right: $spacing-xxxs;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    background-color: white;

    .mediaList {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        row-gap: 4px;
        column-gap: 4px;
        background-color: #fff;
        height: 100%;
        width: 100%;
        list-style: none;
        padding: 0;
        margin: 0;

        > li:last-of-type {
            padding-bottom: 100px;
        }
    }
}
