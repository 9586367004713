@import 'styles/index.scss';

$max-message-size: 400px;
$min-message-size-sm: 250px;
$min-message-size-mobile: 250px;

$max-message-size-sm: 250px;
$max-message-size-mobile: 250px;

.message {
    max-width: $max-message-size;
    padding-left: $spacing-xxxs;
    padding-right: $spacing-xxxs;
    padding-top: $spacing-xxs;
    padding-bottom: $spacing-xxxs;
    border-radius: $border-radius-rounded;
    background-color: #ffffff;
    position: relative;
    margin-top: $spacing-xxxs;
    margin-bottom: $spacing-xxxs;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 1px 4px $box-shadow-color;

    .contextMenuContainer {
        position: absolute;
        right: 0px;
        top: 4px;
        opacity: 0.0;
        width: 30px;
        height: 50px;
        padding-right: 8px;

        .contextMenuButton {
            padding-left: 8px;
            padding-right: 8px;
            justify-self: flex-end;
            height: 20px;
            width: 20px;
            border-radius: $border-radius-rounded;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &:hover {
        .contextMenuContainer {
            cursor: pointer;
            opacity: 1;
            right: 4px;
            transition: opacity 0.2s, right 0.2s;
            .contextMenuButton {
                background-color: $background-light;
            }
        }
    }

    .mediaMessage {
        min-width: $max-message-size;
        min-height: $max-message-size;
        max-width: $max-message-size;
        max-height: $max-message-size;
        background-color: $background-dark;
        border-radius: $border-radius + 1;
    }

    &.ownMessage {
        align-self: flex-end;
        background-color: #d8fcd7;
    }

    &.messageFromAnotherEmployee {
        background-color: #d8fcd7;
    }

    .footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-left: $spacing-xxxs;
        padding-right: $spacing-xxxs;

        .timestamp {
            font-size: $font-size-xs;
            bottom: $spacing-xs;
            right: $spacing-xs;
        }

        .unreadMessageBubble {
            height: 7px;
            width: 7px;
            text-align: center;
            background-color: $active-color;
            border-radius: 50%;
            margin-left: $spacing-xs;
        }
    }
}

.systemMessage {
    align-self: center;
    margin: $spacing-sm auto;
    text-align: center;
    max-width: 75%;
    border-radius: 20%;
    background-color: $background-light;
    border-radius: $border-radius-rounded;
    box-shadow: 0 1px 4px $box-shadow-color;
    @include user-select-none;
    padding-left: 8px;
    padding-right: 8px;

    span {
        font-size: $font-size-sm;
        font-style: italic;
    }
}

@include is-screen-smaller-than(xxs) {
    .message {
        max-width: $max-message-size-mobile;
        .mediaMessage {
            min-width: $min-message-size-mobile;
            min-height: $min-message-size-mobile;
            max-width: $max-message-size-mobile;
        }
    }
}

@include is-screen-smaller-than(sm) {
    .message {
        max-width: $max-message-size-sm;
        .mediaMessage {
            min-width: $min-message-size-sm;
            min-height: $min-message-size-sm;
            max-width: $max-message-size-sm;
        }
    }
}
