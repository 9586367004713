@import 'styles/index.scss';

$adornment-padding: calc(#{$spacing-lg} + #{$icon-size-default});

.container {
    display: flex;
    flex-direction: column;

    label {
        margin-top: $spacing-md;
        margin-bottom: $spacing-md;
        flex: 1;
        font-size: $font-size-md;
    }

    .inputWrapper {
        flex: 1;
        position: relative;
        display: flex;

        input {
            min-height: 26px;
            width: calc(100%); //padding from the input
            box-shadow: inset 0 2px 4px $box-shadow-color;

            &.text {
                margin-bottom: 0;
                padding-left: $spacing-lg;
                padding-right: $spacing-sm;
                padding-top: $spacing-sm;
                padding-bottom: $spacing-sm;

                border: 0;
                border-radius: 5px;
                border: 2px;
                background: white;
                font-size: $font-size-md;
                outline: none;
            }

            &.inputAdornmentStart {
                padding-left: $adornment-padding;
                width: calc(100% - #{$icon-size-default} - 2 * #{$spacing-md});
            }

            &.inputAdornmentEnd {
                padding-right: $adornment-padding;
                width: calc(100% - #{$icon-size-default} - 2 * #{$spacing-md});
            }
        }
    }
}

.error {
    color: $animal-red;
    margin: $spacing-sm 0 0 0;
    text-align: end;
}

.textarea {
    flex: 1;
    font-family: $font-family;
    font-size: $font-size-md;
    padding: $spacing-sm;
    border-radius: $border-radius;
    resize: none;
    max-height: 100px;
    overflow-y: auto;
    margin-bottom: 0;
    border-radius: 24px;
    background: white;
    outline: none;
    white-space: pre-line;
    box-sizing: border-box;

    &:focus-visible {
        outline-color: $background-dark;
    }
}

@include is-screen-smaller-than(xxs) {
    .textarea {
        max-width: calc(100vw - 110px);
    }
}
