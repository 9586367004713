@import 'styles/index.scss';

$size: 300px;

.container {
    padding: $spacing-xl;
    max-width: $size;
    display: flex;
    flex-direction: column;

    .renewalContainer {
        display: flex;
        color: $font-color-sub;
        margin-top: $spacing-xl;
        margin-bottom: $spacing-md;

        .renewalText {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            h3 {
                font-weight: 400;
                margin: 0 0 $spacing-xxxs 0;
            }

            h5 {
                margin: 0;

                font-weight: 400;
            }
        }

        .switch {
        }
    }

    .assignment {
        display: flex;
        flex-direction: column;
        color: $font-color-sub;
        margin-top: $spacing-md;
        margin-bottom: $spacing-md;
        align-items: stretch;

        span {
            font-weight: 500;
            margin-bottom: $spacing-sm;
        }
    }

    .buyButton {
        font-weight: 500;
        margin-bottom: $spacing-xl;
    }
}
