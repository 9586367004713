@import 'styles/index.scss';

.container {
    margin-left: $spacing-xxxs;
    margin-right: -$spacing-xxxs;
    display: flex;
    justify-content: center;

    &.viewed {
        color: $active-color;
    }
}
