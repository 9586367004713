@import 'styles/shared.scss';

.container {
    display: flex;
    flex-direction: row;
    padding: 20px;
    margin-bottom: 160px;

    @include user-select-none;

    .listItem {
        border-radius: $border-radius-rounded;
        background-color: #ffffff;
        box-shadow: 0 0px 8px $box-shadow-color;

        &:hover {
            cursor: pointer;
            background-color: $background-light;
        }
    }

    .stepContainer {
        display: flex;
        flex-direction: column;
        max-width: 400px;

        > * {
            margin: $spacing-sm 0;
        }

        h3 {
            font-size: $font-size-xl;
            margin-top: 0px;
        }

        h4 {
            font-size: $font-size-lg;
            font-weight: 900;
        }

        .stepRow {
            display: flex;
            &:not(:first-of-type) {
                margin-top: $spacing-xl;
            }

            .step {
                font-weight: 700;
            }

            .text {
                flex: 1;
            }

            .checkmark {
                background-color: $animal-green;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 16px;
                width: 16px;
                color: #fff;
                padding: 2px;
            }

            .exclamationMark {
                background-color: $animal-red;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 16px;
                width: 16px;
                color: #fff;
                padding: 2px;
            }
        }

        .button {
            font-weight: 500;
            margin-top: $spacing-xxxs;
        }

        .secondaryButton {
            background-color: #f2f2f2;
            color: #2387fb;
        }
    }

    .imgContainer {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-self: flex-start;
        margin-top: 15rem;
        padding-left: 5rem;

        img {
            max-height: 400px;
        }

        .petImg {
            max-height: 300px;
            align-self: flex-end;

            -moz-transform: scale(-1, 1);
            -webkit-transform: scale(-1, 1);
            -o-transform: scale(-1, 1);
            -ms-transform: scale(-1, 1);
            transform: scale(-1, 1);

            // correct margin inside the svg to align bottom with the vet image
            margin-bottom: -0.65rem;
            margin-left: -1rem;
        }
    }
}

@media only screen and (max-width: 1146px) {
    .container {
        justify-content: flex-start;
    }

    .imgContainer {
        display: none !important;
    }
}
