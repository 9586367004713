@import 'styles/index.scss';

$image-size: 200px;

.container {
    padding-bottom: 180px;
    padding-left: 20px;
    padding-right: 20px;

    .formFields {
        flex: 1;
        display: grid;
        gap: $spacing-md;
        grid-template-columns: 2fr 3fr;
        align-content: start;
        align-items: center;
        max-width: 700px;
        overflow-y: auto;

        .actions {
            grid-column-start: span 2;
            gap: $spacing-sm;
            display: flex;
            justify-content: flex-end;
            @include user-select-none;
        }
    }
}

@include is-screen-smaller-than(sm) {
    .container {
        .formFields {
            display: block;

            > label {
                display: block;
                margin-top: $spacing-md;
                margin-bottom: $spacing-xs;
            }

            .profileContainer {
                width: 100%;
                display: flex;
                justify-content: center;
            }
        }
    }
}
