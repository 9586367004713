@import 'styles/index.scss';

.message {
    padding-left: $spacing-xxxs;
    padding-right: $spacing-xxxs;
    padding-top: $spacing-xxs;
    padding-bottom: $spacing-xxxs;
    border-radius: $border-radius-rounded;
    background-color: #ffffff;
    margin-top: $spacing-xxxs;
    margin-bottom: $spacing-xxxs;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 0 1px 4px $box-shadow-color;

    .messageContentContainer {
        display: flex;
        flex: 1;
        flex-direction: row;
        align-self: flex-end;
    }

    .messageTextContainer {
        display: flex;
        flex: 1;
        flex-direction: column;
        width: 100%;
        word-wrap: break-word;
    }

    .messageTextContainerWithMedia {
        display: flex;
        flex: 1;
        flex-direction: column;
        width: 70%;
    }

    .mediaMessage {
        background-color: $background-dark;
        border-radius: $border-radius + 1;
        flex: 0.5;
        flex-direction: column;
        align-self: flex-start;
        min-width: 100px;
    }

    &.ownMessage {
        align-self: flex-start;
        background-color: #d8fcd7;
    }
}
