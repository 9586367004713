@import 'styles/index.scss';

.container {
    flex: 1;
    display: flex;
    height: 100%;
  
    & > *:first-child {
      flex: 0.33;
      border-right: 1px solid $box-shadow-color;
      overflow-y: auto;
    }
    & > *:last-child {
      flex: 0.67;
      overflow-y: auto;
    }
  }
  
  @include is-screen-smaller-than(sm) {
    .container {
      flex: 1;
      width: 100vw;
      left: 0px;
      right: 0px;
  
      & > *:first-child {
        flex: 1;
        border-right: 1px solid $box-shadow-color;
      }
      & > *:last-child {
        position: absolute;
        height: 100vh;
        width: 0;
        top: 0px;
        right: 0px;
        left: 100vw;
        bottom: 0px;
        transition: 0.2s;
        z-index: $z-index-mobile-splitscreen-right;
        overflow-y: auto;
        overflow-x: hidden;
        background: white;
        border-left: 1px solid $box-shadow-color;

      }
    }
  }
  