@import 'styles/shared.scss';

$animation-time: 0.2s;

.detail {
    max-width: $chat-detail-width;
    width: 0px;
    display: flex;
    flex-direction: column;
    transition: width $animation-time ease-in;
    overflow: hidden;

    @include is-screen-larger-than(md) {
        &.shown,
        &.show {
            width: $chat-detail-width;
        }
    }

    .container {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        z-index: $z-index-chat-detail;
        position: relative;

        .subPage {
            position: absolute;
            top: 0px;
            left: 0px;
            bottom: 0px;
            width: $chat-detail-width;
            height: 100%;
            overflow: hidden;
            z-index: $z-index-chat-detail + 100;
            visibility: visible;
            opacity: 1;
            transition: left $animation-time ease-in;
            background-color: #fff;
        }

        .subPageHidden {
            position: absolute;
            top: 0px;
            left: $chat-detail-width;
            width: $chat-detail-width;
            height: 100%;
            overflow: hidden;
            z-index: $z-index-chat-detail + 100;
            visibility: hidden;
            opacity: 0;
            -webkit-transition: left $animation-time ease-out,
                visibility $animation-time + 1 linear,
                opacity $animation-time + 1 linear;
            -moz-transition: left $animation-time ease-out,
                visibility $animation-time + 1 linear,
                opacity $animation-time + 1 linear;
            -o-transition: left $animation-time ease-out,
                visibility $animation-time + 1 linear,
                opacity $animation-time + 1 linear;
            transition: left $animation-time ease-out,
                visibility $animation-time + 1 linear,
                opacity $animation-time + 1 linear;
        }
    }
}

@include is-screen-smaller-than(md) {
    @include fade-from-right(#{$chat-detail-width});

    .detail {
        max-width: unset;
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        width: $chat-detail-width;
        transition: unset;
        display: none;
        border-left: 1px solid $border-color;

        &.show {
            display: flex;
            z-index: $z-index-chat-detail;
            animation: fade-from-right $animation-time;
        }

        &.hide {
            display: flex;
            z-index: $z-index-chat-detail;
            animation: fade-from-right $animation-time reverse;
        }

        &.shown {
            display: flex;
            z-index: $z-index-chat-detail;
            right: 0;
        }

        &.hidden {
            display: none;
            right: calc(-1 * #{$chat-detail-width});
        }
    }

    @include is-screen-smaller-than(xxs) {
        .detail {
            width: 100vw;

            .container {
                flex: 1;
                display: flex;
                flex-direction: column;
                overflow-x: hidden;
                z-index: $z-index-chat-detail;
                position: relative;
                z-index: 1000;
                .subPage {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100vw;
                    height: 100%;
                    overflow: hidden;
                    z-index: $z-index-chat-detail + 100;
                    visibility: visible;
                    opacity: 1;
                    transition: left $animation-time ease-in;
                }

                .subPageHidden {
                    position: absolute;
                    top: 0px;
                    left: $chat-detail-width;
                    width: 100vw;
                    height: 100%;
                    overflow: hidden;
                    z-index: $z-index-chat-detail + 100;
                    visibility: hidden;
                    opacity: 0;
                    -webkit-transition: left $animation-time ease-out,
                        visibility $animation-time + 1 linear,
                        opacity $animation-time + 1 linear;
                    -moz-transition: left $animation-time ease-out,
                        visibility $animation-time + 1 linear,
                        opacity $animation-time + 1 linear;
                    -o-transition: left $animation-time ease-out,
                        visibility $animation-time + 1 linear,
                        opacity $animation-time + 1 linear;
                    transition: left $animation-time ease-out,
                        visibility $animation-time + 1 linear,
                        opacity $animation-time + 1 linear;
                }
            }
        }
    }
}
