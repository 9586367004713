@import 'styles/index.scss';

.container {
    .unreadMessageCountBubble {
        // TODO: create component with badge from menu
        font-size: 12px;
        color: #ffffff;
        height: 20px;
        width: 20px;
        text-align: center;
        background-color: $active-color;
        border-radius: 50%;
        margin-left: $spacing-xs;
        margin-bottom: 4px;
    }

    .unreadMessageCountBubbleText {
        padding-top: 2px;
    }
}
