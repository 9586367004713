@import 'styles/index.scss';

/* Customize the label (the container) */
.container {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 2px solid $font-color;
    border-radius: 5px;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        ion-icon {
            --ionicon-stroke-width: 60px;
        }

        .icon {
            color: $active-color;
        }
    }

    &:hover {
        cursor: pointer;
    }
}
