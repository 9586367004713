@import 'styles/index.scss';

.fileInput {
    display: none;
    visibility: hidden;
}

.container {
    display: flex;
    justify-content: stretch;
    align-items: center;
    padding: $spacing-xs 0;
    background-color: $background-light;
    transition: flex 0.2s ease-in-out;
    border-right: 1px solid $border-color;
    border-left: 1px solid $border-color;
    font-weight: 500;
    position: relative;

    > svg,
    ion-icon {
        @include user-select-none;
        margin-right: $spacing-sm;
        margin-left: $spacing-sm;
        &:hover {
            cursor: pointer;
        }
    }

    .contextMenu {
        height: calc(100% + #{$spacing-xs} + #{$spacing-xs});
        display: flex;
        justify-content: center;
        align-items: stretch;

        .contextMenuButton {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .input {
        flex: 1;
        padding-right: $spacing-xs;
    }

    .emojiIcon {
        margin-left: 0px;
    }

    .filled {
        fill: $primary-color;
        color: $primary-color;
    }

    .send {
        fill: $primary-color;
        color: $primary-color;
    }

    .loadingMediaFileMessagesSpinner {
        height: 40px;
        display: flex;

        > span:first-of-type {
            top: 0px;
            right: 0px;
            transform: scale(0.5);
            padding-right: 10px;
        }
    }
}
