@import 'styles/index.scss';

.container {
    flex: 1;
    height: 100%;
}

.header {
    @include basic-header;
    align-items: center;
    font-weight: 700;
    padding-left: $spacing-md;
}

.content {
    background-color: #fff;
    flex: 1;
    height: calc(100% - #{$header-height-with-padding});
    overflow: auto;
}

@include is-screen-smaller-than(xxs) {
    .header {
        display: flex;
        position: fixed;
        top: 0;
        padding-left: 70px;
        width: 100vw;
        z-index: $z-index-mobile-main-header;
    }
    .content {
        padding-top: 70px;
        width: 100vw;
    }
}
