@mixin is-screen-larger-than($size) {
  @if $size == xxs {
    @media (min-width: 501px) {
      @content;
    }
  } @else if $size == xs {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == sm {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == md {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == lg {
    @media (min-width: 1536px) {
      @content;
    }
  } @else if $size == xl {
    @media (min-width: 1920px) {
      @content;
    }
  }
}

@mixin is-screen-smaller-than($size) {
  @if $size == xxs {
    @media (max-width: 501px) {
      @content;
    }
  } @else if $size == xs {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == sm {
    @media (max-width: 899px) {
      @content;
    }
  } @else if $size == md {
    @media (max-width: 1199px) {
      @content;
    }
  } @else if $size == lg {
    @media (max-width: 1535px) {
      @content;
    }
  } @else if $size == xl {
    @media (max-width: 1919px) {
      @content;
    }
  }
}
