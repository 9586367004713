@import 'styles/shared.scss';

.container {
    width: 100%;

    .inputContainer > div:first-child {
        border-radius: $border-radius-rounded;
   
        @include user-select-none;
    }

    .prefixText {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: $spacing-md;
    }

    .phoneNumberInput {
        border-radius: $border-radius-rounded !important;
        padding-left: calc(#{$spacing-lg} + 40px) !important;
        text-align: left !important;
    }

    .error {
        color: $animal-red;
        margin: $spacing-sm 0;
    }

    .inputError {
        display: none;
    }
}
