@import 'styles/index.scss';

$field-size: 0.4;

.container {
    flex: 1;
    padding: $spacing-md $spacing-xxxl $spacing-md $spacing-xl;
    display: grid;
    gap: $spacing-md;
    grid-template-columns: 2fr 3fr;
    align-content: start;
    align-items: center;
    min-width: 480px;

    .employeeName {
        flex: $field-size;
        font-weight: 700;
        color: #000000;
    }

    .editIcon:hover {
        cursor: pointer;
    }

    .actions {
        grid-column-start: span 2;
        gap: $spacing-sm;
        display: flex;
        justify-content: flex-end;
        @include user-select-none;
    }

    .deleteButtonContainer {
        grid-column-start: span 2;
        display: flex;
        justify-content: flex-end;
        @include user-select-none;

        .deleteButton {
            color: $error-color;
            margin-bottom: 100px;
        }
    }
}

@include is-screen-smaller-than(lg) {
    .container {
        display: block;
        padding-left: $spacing-md;
        padding-right: $spacing-md;
        align-content: center;
        min-width: 300px;

        > label {
            display: block;
            margin-top: $spacing-md;
            margin-bottom: $spacing-xs;
        }
    }
}
