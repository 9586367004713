@import 'styles/index.scss';

.container {
    padding: $spacing-md;
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 400px;
    min-width: 300px;
    @include user-select-none;

    .title {
        font-weight: 700;
    }

    .openingStatusSwitch {
        display: block;
        padding-top: $spacing-sm;
    }

    .row {
        display: flex;
        font-size: $font-size-sm;

        .day {
            font-size: $font-size-md;
            font-weight: 700;
        }

        .addButton {
            margin-left: $spacing-md;
            &:hover {
                cursor: pointer;
            }
        }

        .openingStatus {
            flex: 1;
            font-weight: 500;
        }

        .times {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding-top: $spacing-xs;
            padding-bottom: $spacing-xs;
            margin-bottom: $spacing-xs;

            align-items: flex-start;

            .inputContainer {
                display: flex;
                flex-direction: row;
                align-items: center;

                .input {
                    padding-top: $spacing-xs;
                    padding-bottom: $spacing-xs;
                    flex: 1;
                }

                .inputSeparator {
                    padding-top: $spacing-lg;
                    padding-bottom: $spacing-md;
                    padding-left: $spacing-xs;
                    padding-right: $spacing-xs;
                    flex: 1;
                    font-size: $font-size-md;
                    color: $background-dark;
                }

                .deleteButton {
                    margin-left: $spacing-md;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .actions {
        gap: $spacing-sm;
        display: flex;
        justify-content: flex-end;
        padding: $spacing-md;
        padding-bottom: 100px;
        @include user-select-none;
    }
}

@include is-screen-smaller-than(sm) {
    .container {
        padding-left: $spacing-md;
        padding-right: $spacing-md;
        align-content: center;
        flex: 1;
        overflow-y: auto;
    }
}
