@import 'styles/index.scss';

$modal-animation-time: 0.3s;

.container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: $z-index-modal;
    display: none;
    opacity: 0;
    justify-content: center;
    align-items: center;

    @keyframes show-modal {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    &.show {
        display: flex;
        animation: show-modal $modal-animation-time;
    }

    &.hide {
        display: flex;
        opacity: 1;
        animation: show-modal $modal-animation-time reverse;
    }

    &.shown {
        display: flex;
        opacity: 1;
    }

    .box {
        background-color: $background-light;
        box-shadow: 0 2px 8px $box-shadow-color;
        border-radius: $border-radius-rounded;
        margin-left: $spacing-md;
        margin-right: $spacing-md;
        max-width: 500px;

        .titleBox {
            border-bottom: 1px solid $border-color;
            padding-left: $spacing-md;
            padding-right: $spacing-md;

            .title {
                margin-top: $spacing-sm;
                margin-bottom: $spacing-sm;
            }
        }
        .contentBox {
            padding: $spacing-xl $spacing-md;
        }

        .actionsBox {
            border-top: 1px solid $border-color;
            padding-right: $spacing-sm;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .button {
                padding-top: $spacing-sm;
                padding-bottom: $spacing-sm;
                margin-top: $spacing-xxs;
                margin-bottom: $spacing-xxs;
                margin-left: $spacing-xxs;

                &:hover {
                    background-color: #a5a7aa;
                }
            }
        }
    }
}
