@import 'styles/shared.scss';

.navigation {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: $chat-navigation-width;
    flex: 0.5;
}

@include is-screen-smaller-than(xxs) {
    .navigation {
        min-width: 340px;
        flex: 1;
    }
}
