@import 'styles/index.scss';

$nav-btn-size: 40px;
$transition-time: 0.2s;

.navBackBtn {
    &:hover {
        cursor: pointer;
    }

    height: $nav-btn-size;
    width: $nav-btn-size;
    transition: visibility $transition-time linear,
        opacity $transition-time linear;
    opacity: 1;
    position: absolute;
    border-radius: 50%;
    top: $spacing-md;
    left: $spacing-md;
    background-color: $background-blue-darker;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
