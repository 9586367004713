@import 'styles/index.scss';

$image-size: 200px;

.container {
    flex: 1;
    display: block;
    align-content: start;
    align-items: center;
    max-width: 660px;
    overflow-y: auto;
    background-color: $background-light;
    border-radius: $border-radius-rounded;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 10px;
    margin-bottom: 20px;

    .actions {
        grid-column-start: span 2;
        gap: $spacing-sm;
        display: flex;
        justify-content: flex-end;
        @include user-select-none;
    }
}

@include is-screen-smaller-than(sm) {
    .container {
        display: block;
        background-color: $background-light;

        > label {
            display: block;
            margin-top: $spacing-md;
            margin-bottom: $spacing-xs;
        }

        .profileContainer {
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }
}
