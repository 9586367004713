@import 'styles/shared.scss';

.container {
    padding: 20px;
    padding-top: $spacing-xxl;

    p {
        &:first-of-type {
            margin-top: 0;
        }
        margin-bottom: $spacing-md;
        padding: 0;
    }

    .button {
        margin-top: $spacing-md;
        margin-bottom: $spacing-md;
        max-width: 300px;
        &.secondaryButton {
            background-color: #f2f2f2;
            color: #2387fb;
        }
    }
}
