@import 'styles/index.scss';

.input {
    & > div {
        border-radius: $border-radius-rounded;
    }
}

.label {
    font-weight: 500;
    color: $font-color;
    margin-bottom: 0.15rem;
    display: flex;
    align-items: flex-start;
    padding-top: 16px;
    font-size: $font-size-md;

    .exclamationMark,
    .checkmark {
        width: 12px;
        height: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px;
        border-radius: 50%;
        color: #fff;
        margin-left: $spacing-sm;
    }

    .exclamationMark {
        background-color: $animal-red;
    }

    .checkmark {
        background-color: $animal-green;
    }
}
