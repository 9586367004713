@import 'styles/index.scss';

.container {
    min-height: 58px;
    background-color: $background-light;
    flex: 1;

    .title {
        padding-top: $spacing-xxxs;
        padding-bottom: $spacing-xs;
        margin-left: $spacing-md;
        margin-right: $spacing-md;
        font-size: $font-size-md;
        font-weight: 700;
    }

    .form {
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        padding-top: $spacing-xs;
        padding-bottom: $spacing-xs;

        .field {
            margin: $spacing-md;
            display: flex;

            > label {
                flex: 0.4;
                display: flex;
                align-items: center;
            }

            > *:nth-child(2) {
                flex: 0.6;
                display: flex;

                & > *:first-child {
                    flex: 1;
                }
            }

            &.datepicker {
                .reactDatepicker {
                    z-index: $z-index-appointment-datepicker;
                }

                & > div:first-of-type {
                    & > div:first-of-type {
                        display: flex;
                        & > input {
                            flex: 1;
                        }
                    }
                }
            }

            input {
                border-radius: $border-radius-rounded;
                font-size: $font-size-sm;
                padding: $spacing-xs;
                box-shadow: none;
                border: none;
                height: 1rem;
            }

            .switchContainer {
                display: flex;
                align-items: center;

                .switch {
                    flex: unset;
                }
            }
        }
    }

    .actions {
        padding-top: $spacing-xxxs;
        padding-bottom: $spacing-md;

        display: flex;
        justify-content: space-evenly;
        gap: $spacing-sm;
        @include user-select-none;

        button {
            &:first-of-type {
                margin-left: $spacing-md;
            }
            &:last-of-type {
                margin-right: $spacing-md;
            }
        }
    }
}
