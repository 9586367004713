@import 'styles/index.scss';

.menu {
    position: absolute;
    background: linear-gradient(
        $background-blue-darker,
        $background-blue-darker
    );
    margin-top: 30px;
    padding-bottom: 20px;
    top: 0;
    left: 0;
    bottom: 0;
    width: $menu-width;
    display: flex;
    flex-direction: column;
    font-size: $font-size-sm;
    color: #ffffff;
    border-top-right-radius: $menu-border-radius;
    @include user-select-none;
    z-index: $z-index-menu;

    .separator {
        flex: 1;
    }

    .logo {
        padding-top: $spacing-sm;
        padding-bottom: $spacing-sm;

        .icon {
            height: 44px;
            width: 44px;
        }
    }

    .videoCall {
        ion-icon {
            fill: $animal-red;
            color: $animal-red;
        }
    }

    .profile {
        display: flex;
        justify-content: center;
        padding-top: $spacing-xs;
        padding-bottom: $spacing-xs;

        &.active {
            cursor: pointer;
            background-color: $active-overlay-color;
        }
        &:hover {
            cursor: pointer;
            background-color: $active-overlay-color;
        }

        .profileIcon {
            border: 3px solid #ffffff;
            border-radius: 50%;
            height: 44px;
            width: 44px;
        }
    }
}

// @include is-screen-smaller-than(xxs) {
//   .menu {
//     position: absolute;
//     left: -$menu-width;
//     transition: 1s;
//   }
// }
