@import 'styles/index.scss';

$width: 160px;

.container {
    background-color: $primary-color;
    position: fixed;
    top: calc(#{$width} * 0.1);
    right: calc(#{$width} * -0.25);
    width: $width;
    height: 40px;
    color: #fff;
    z-index: $z-index-system-banner;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $font-size-sm;
    font-weight: 500;
    transform: rotate(45deg);
}
