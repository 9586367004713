@import 'styles/shared.scss';

.container {
    height: 100vh;
    padding-right: 20px;
    position: relative;
    width: 240px;
    background-color: white;
    z-index: $z-index-menu;
    @include user-select-none;
    -webkit-app-region: drag;

    .menu {
        position: absolute;
        height: calc(100vh - 80px);
        overflow-y: auto;
        padding-bottom: 0;
        left: 0px;
        transition: left 0.2s ease-in-out;
        box-shadow: 0 2px 4px $box-shadow-color;
        min-width: 240px;
        width: 240px;
        flex: 1;
        padding-right: 20px;
        background-color: white;
    }

    .menuHidden {
        position: absolute;
        height: calc(100vh - 80px);
        overflow-y: auto;
        padding-bottom: 0;
        left: -240px;
        box-shadow: 0 2px 4px $box-shadow-color;
        min-width: 240px;
        width: 240px;
        padding-right: 20px;
        flex: 1;
    }
}

.containerHidden {
    height: 100vh;
    padding-right: 0px;
    position: relative;
    width: 0px;
    transition: width 0.2s ease-in-out;
    background-color: white;
    z-index: $z-index-menu;

    .menu {
        position: absolute;
        height: calc(100vh - 80px);
        overflow-y: auto;
        padding-bottom: 0;
        left: 0px;
        transition: left 0.2s ease-in-out;
        box-shadow: 0 2px 4px $box-shadow-color;
        min-width: 240px;
        width: 240px;
        flex: 1;
        padding-right: 0px;
    }

    .menuHidden {
        position: absolute;
        height: calc(100vh - 80px);
        overflow-y: auto;
        padding-bottom: 0;
        left: -240px;
        box-shadow: 0 2px 4px $box-shadow-color;
        min-width: 240px;
        width: 240px;
        padding-right: 0px;

        flex: 1;
    }
}

@include is-screen-smaller-than(xs) {
    .container {
        width: 54px;
        transition: width 0.2s ease-in-out;
        padding-right: 0px;
        background-color: white;
        z-index: $z-index-menu;

        .menu {
            position: absolute;
            min-width: 240px;
            width: 240px;
            padding-right: 2px;
            transition: left 0.3s ease-in-out;
            height: calc(100vh - 80px);
        }
        .menuHidden {
            min-width: 54px;
            width: 54px;
            padding-right: 2px;
            transition: left 0.3s ease-in-out;
            left: 0px;
            height: calc(100vh - 80px);
        }
    }

    .containerHidden {
        width: 54px;
        transition: width 0.2s ease-in-out;
        z-index: $z-index-menu;

        .menu {
            position: absolute;
            min-width: 54px;
            width: 54px;
            padding-right: 2px;
            transition: left 0.3s ease-in-out;
            height: calc(100vh - 80px);
        }
        .menuHidden {
            min-width: 54px;
            width: 54px;
            padding-right: 2px;
            transition: left 0.3s ease-in-out;
            left: 0px;
            background-color: white;
            height: calc(100vh - 80px);
        }
    }
}
