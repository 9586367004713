@import 'styles/index.scss';

.icon {
    position: absolute;
    top: 50%;

    &.start {
        left: $spacing-md;
        transform: translateY(-50%);
    }

    &.end {
        right: $spacing-md;
        transform: translateY(-50%);
    }

    &.hover:hover {
        cursor: pointer;
    }
}
