@import 'styles/index.scss';

.container {
    min-height: 60px;
    display: flex;
    justify-content: left;
    flex-direction: row;
    align-items: center;
    border-radius: $border-radius;
    overflow: hidden;
    box-shadow: inset 0 10px 10px $box-shadow-color;
    padding-right: 4px;
    padding-top: 4px;

    .fileTypeSymbol {
        padding-right: 10px;
        padding-left: 4px;
        .fileTypeText {
            font-size: 40px;
        }
    }

    .fileInfoContainer  {
        .fileName {
            color: $font-color;
            margin: 0;
            font-weight: 500;
            font-size: 0.9rem;
            white-space: nowrap;
            overflow-x: hidden;
            text-overflow: ellipsis;
            width: 100%;
            max-width: 100px;
            min-height: 20px;
        }

        .fileInfo {
            color: $font-color;
            margin: 0;
            font-weight: 500;
            font-size: 0.9rem;
            white-space: nowrap;
            overflow-x: hidden;
            text-overflow: ellipsis;
            width: 100%;
            max-width: 100px;
            min-height: 10px;
        }
    }

    .downloadIcon {
        padding-left: 10px;
        padding-right: 4px;
        padding-bottom: 4px;
    }

    &:hover {
        cursor: pointer;
    }
}
