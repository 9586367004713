@import 'styles/index.scss';

.container {
    height: 100%;
    overflow: hidden;
    -webkit-app-region: drag;

    .header {
        @include basic-header;
        align-items: center;
        justify-content: flex-start;
        padding-right: $spacing-md;
        padding-left: 100px;

        .practice {
            margin-left: $spacing-md;

            p {
                margin: 0;
                font-weight: 700;
            }

            span {
                font-size: $font-size-sm;
            }
        }
    }

    .menu {
        height: calc(100% - #{$header-height-with-padding});
        overflow-y: auto;
        padding-top: 0;
        padding-bottom: 0;
    }
}
