@import 'styles/shared.scss';

.container {
    @include customer-profile-media-preview-container;

    &.clickable:hover {
        cursor: pointer;
    }

    &.loadingSpinner {
        > span {
            > span {
                background-color: #fff;
            }
            margin-left: 10px; // = (18 + FadeSpinner.margin) / 2
        }
    }

    .image {
        border-radius: $border-radius;
        object-fit: cover;
        flex: 1;
        max-height: 100%;
        max-width: 100%;
        height: 100%;
        width: 100%;
    }

    .imageHidden {
        display: none;
    }
}
