@import 'styles/shared.scss';

.text {
    margin: $spacing-sm 0;
    font-weight: 500;
    @include user-select-none;
}

.form {
    flex: 1;

    .prefixText {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: $spacing-md;
    }

    .inputContainer {
        margin: $spacing-sm 0;
    }

    .input {
        border-radius: $border-radius-rounded !important;
        padding-left: calc(#{$spacing-lg} + 40px) !important;
        text-align: left !important;
    }
}

.button {
    margin: $spacing-sm 0;
    font-weight: 500;
}
