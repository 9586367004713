@import 'styles/shared.scss';

.main {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

@include is-screen-smaller-than(xxs) {
    .navigation {
        display: flex;
        flex-direction: column;
        width: 100vw;
    }
    .main {
        position: absolute;
        top: 0px;
        right: 0px;
        left: calc(100vw);
        bottom: 0px;
        transition: 0.2s;
        z-index: $z-index-mobile-main;
    }
}
