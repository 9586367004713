@import 'styles/index.scss';

.container {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    background-color: $background-chat;
    background-image: url('/assets/images/chat_background_infinite.png');
    background-repeat: repeat;
    background-size: 400px;

    min-height: 100vh;

    overflow-y: auto;
    box-shadow: inset 0 2px 4px $box-shadow-color;

    padding-bottom: 30px;
    padding-left: calc(10%);
    padding-right: calc(10%);

    .loadingNextPageSpinner {
        min-height: 80px;
        width: 100%;
        display: flex;
        flex: 1;

        > span:first-of-type {
            top: 20px;
            left: 10px;
            transform: scale(0.5);
            margin-left: auto;
            margin-right: auto;
        }
    }
}
