@import 'styles/index.scss';

.pendingTime {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $font-size-xs;
    background-color: $animal-green;

    color: #000000;
    text-align: center;
    white-space: nowrap;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 2px;
    padding-bottom: 2px;
    transition: width 0.2s;

    &.roundCorners {
        border-radius: $border-radius;
    }

    &.yellow {
        background-color: $animal-yellow;
    }

    &.red {
        color: #ffffff;
        background-color: $animal-red;
    }

    .icon {
        margin-left: $spacing-xxxs;
        justify-content: center;
        align-items: center;
        display: flex;
    }

    &:hover {
        cursor: pointer;
    }
}
