@import 'styles/index.scss';

.container {
    display: flex;
    flex-direction: column;
    padding: $spacing-sm;
    min-width: 300px;

    .timeTable {
        display: flex;
        flex-direction: column;

        .row {
            flex: 1;
            display: flex;
            padding: $spacing-xs;
            padding-bottom: $spacing-sm;

            .editIcon {
                align-self: center;

                &:hover {
                    cursor: pointer;
                }
            }

            .day {
                flex: 0.5;
                font-size: $font-size-sm;
            }

            .times {
                font-size: $font-size-sm;
                padding-left: $spacing-md;
                display: flex;
                flex-direction: column;
            }

            .title {
                flex: 1;
                color: $primary-color;
            }
        }
    }
}

@include is-screen-smaller-than(sm) {
    .container {
        min-width: 100px;
        width: 100px;
    }
}
