@import 'styles/shared.scss';

.loading {
    flex: 1;
    justify-content: center;
    display: flex;
    padding-top: $spacing-lg;
    @include spinner-color;
}

.container {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: $background-light;
    overflow-y: auto;
    height: 100vh;

    & > div {
        background-color: #ffffff;
        padding-top: $spacing-md;
        padding-left: $spacing-md;
        padding-right: $spacing-md;
        padding-bottom: $spacing-md;

        &:not(:last-of-type) {
            margin-bottom: $spacing-md;
        }
    }

    .title {
        display: flex;
        align-items: center;
        font-size: $font-size-sm;
        margin-bottom: $spacing-sm;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        @include user-select-none;

        span {
            flex: 1;
            color: $primary-color;
        }
    }

    .profilePicture {
        .profileIcon {
            flex: 1;
            display: flex;
            justify-content: center;
            margin-top: 14px;
            @include user-select-none;
        }

        .profileName {
            text-align: center;
            font-size: $font-size-lg;
        }

        p {
            margin-bottom: 0;
            font-weight: 700;
        }

        span {
            font-size: $font-size-xs;
        }
    }

    .animals .profiles {
        display: flex;

        .profile {
            border: 2px solid $border-color;
            background-color: #fff;
            border-radius: 50%;
            @include user-select-none;

            &:not(:first-of-type) {
                margin-left: -$spacing-md;
            }
        }
    }

    .media {
        .title {
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            &:hover {
                cursor: pointer;
            }
        }

        .preview {
            display: flex;
            justify-content: space-between;
            column-gap: 1.5%;
            overflow: hidden;
            @include user-select-none;

            .previewItem {
                width: 30%;
            }
        }
    }

    .info .detail {
        font-size: $font-size-md;

        hr {
            margin-right: $spacing-xl;
        }
    }

    .address .detail {
        display: flex;
        flex-direction: column;

        span {
            font-size: $font-size-md;
            margin-bottom: $spacing-xxxs;
        }
    }

    .customerData .detail {
        span {
            font-size: $font-size-xs;
            margin-bottom: $spacing-xxxs;
        }
    }
}
