@import 'styles/index.scss';

.container {
    @include basic-header;

    .personDetail {
        padding-left: $spacing-md;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .title {
            margin: 0;
            font-weight: 700;

            &:hover {
                cursor: pointer;
            }
        }

        .subTitle {
            margin: 0;
            color: $font-color-sub;
            font-size: $font-size-sm;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: calc(90vw - #{$chat-navigation-width});

            @include is-screen-smaller-than(sm) {
                max-width: calc(85vw - #{$chat-navigation-width});
            }

            @include is-screen-smaller-than(xxs) {
                max-width: 60vw;
            }

            .subTitlePrefix {
                color: $background-blue-dark;
                font-size: $font-size-sm;
                font-weight: 500;
            }

            &:hover {
                cursor: pointer;
            }
        }

        .onlineStatus {
            margin: 0;
            font-size: $font-size-sm;
        }
    }

    @include is-screen-smaller-than(xxs) {
        padding-left: 70px;
    }

    .actions {
        display: flex;
        display: none;

        align-items: center;

        ion-icon {
            margin-right: $spacing-xxxs;
            margin-left: $spacing-xxxs;
        }
    }

    .profileIcon {
        z-index: 2;
        border: 2px solid $background-light;
        background-color: transparent;
        border-radius: 50%;
        @include user-select-none;
    }

    .secondaryIcon {
        left: -$spacing-lg;
        margin-right: -$spacing-lg;
        z-index: 1;

        border: 2px solid $background-light;
        background-color: transparent;
        border-radius: 50%;
    }
}
