@import 'styles/index.scss';

$counter-size: 18px;
$half-counter-size: calc(#{$counter-size} / -2);

.entry {
    &:first-of-type {
        margin-top: $spacing-lg;
    }
    &:last-of-type {
        margin-bottom: $spacing-lg;
    }

    display: flex;
    align-items: center;
    margin-bottom: $spacing-sm;
    padding: $spacing-md;
    overflow: hidden;
    max-height: 20px;

    &:hover {
        cursor: pointer;
    }

    &:hover,
    &.active {
        background-color: $background-light;
        border-top-right-radius: $border-radius-very-rounded;
        border-bottom-right-radius: $border-radius-very-rounded;
    }

    .iconContainer {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: $spacing-md;

        // ion-icon {
        // }

        .counter {
            position: absolute;
            top: $half-counter-size;
            right: $half-counter-size;
            background-color: $animal-red;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: $font-size-xxs;
            height: $counter-size;
            width: $counter-size;
            color: #fff;
        }
    }

    &.disabled {
        ion-icon,
        span {
            color: $button-disabled;
        }

        &:hover {
            background-color: transparent;
            cursor: default;
        }
    }
}
